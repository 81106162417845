import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, makeStyles, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useCan } from '../../utils/can';
import { rules } from '../../utils/rbac-rules';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { SURGERY_QUERY } from './use-surgery';
import { ALL_SURGERIES_QUERY } from './use-all-surgeries';
import { organStatus } from '../../constants/status';

const useStyles = makeStyles((theme) => ({
  confirmTransplantButton: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

const CONFIRM_TRANSPLANT_MUTATION = gql`
  mutation ConfirmTransplant($organId: ID!) {
    updateOrganStatus(id: $organId, status: "Trasplantado") {
      id
    }
  }
`;

interface ConfirmTransplantButtonProps {
  surgery: { id: string };
  organ: { id: string; status: string; organType: { name: string } };
}

const ConfirmTransplantButton: React.FC<ConfirmTransplantButtonProps> = ({ surgery, organ }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const canConfirmTransplant = useCan({ perform: rules.surgery.confirm });
  const [confirmTransplant, { loading }] = useMutation(CONFIRM_TRANSPLANT_MUTATION, {
    refetchQueries: [
      { query: SURGERY_QUERY, variables: { surgeryId: surgery.id } },
      { query: ALL_SURGERIES_QUERY },
    ],
  });

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSubmit = async () => {
    closeModal();

    try {
      await confirmTransplant({ variables: { organId: organ.id } });
      enqueueSnackbar('Trasplante confirmado exitosamente', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Hubo un error al confirmar el trasplante', {
        variant: 'error',
      });
    }
  };

  if (!canConfirmTransplant || organ?.status === organStatus.transplanted) return null;

  return (
    <>
      <Button
        className={classes.confirmTransplantButton}
        onClick={() => openModal()}
        variant="contained"
        color="primary"
        size="small"
        data-testid="confirm-surgery-button"
      >
        Confirmar trasplante
      </Button>
      <ResponsiveDialog
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="confirm-suspicion-dialog"
      >
        <DialogTitle id="confirm-suspicion-dialog">
          Confirmar trasplante exitoso de {organ.organType.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer confirmar el trasplante exitoso de{' '}
            {organ.organType.name.toLowerCase()}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Volver
          </Button>
          <Button onClick={handleSubmit} disabled={loading} color="primary" type="submit">
            Confirmar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default ConfirmTransplantButton;
