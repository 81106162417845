import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { useCurrentUser } from "../../features/authentication/use-current-user";

const useStyles = makeStyles({
	root: {
		display: "flex",
		minHeight: "fit-content",
	},
	avatar: {
		width: 45,
		height: 35,
	},
	welcome: {
		marginTop: 8,
	},
});

const Profile: React.FC = () => {
	const classes = useStyles();
	const { user } = useCurrentUser();

	return (
		<div className={classes.root}>
			<PersonIcon className={classes.avatar} />
			<Box fontSize='14px' className={classes.welcome}>
				Hola&nbsp;
			</Box>
			<Box
				fontSize='14px'
				fontWeight='fontWeightBold'
				className={classes.welcome}
			>
				{user?.firstName}
			</Box>
		</div>
	);
};

export default Profile;
