import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Box,
  Typography,
} from "@material-ui/core";
import { gql } from "apollo-boost";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import FormDropzoneArea from "../../components/forms/form-dropzone-area";
import FormTextField from "../../components/forms/form-text-field";
import ResponsiveDialog from "../../components/ui/responsive-dialog";
import { useCurrentUser } from "../authentication/use-current-user";
import Yup from "../../utils/validations";
import Can from "../../utils/can";
import { rules } from "../../utils/rbac-rules";
import { SUSPICION_QUERY } from "../suspicions/use-suspicion";
import { uploadFile } from "../../utils/aws";

const SUPPORTED_FORMATS = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  ".xls",
  ".xlsx",
  ".doc",
  ".docx",
];

const medicalRecordSchema = Yup.object({
  comments: Yup.string().optional(),
});

const useStyles = makeStyles((theme) => ({
  filesContainer: {
    display: "flex",
    "& > *:not(:last-child)": { margin: theme.spacing(0, 1, 0, 0) },
  },
  extensions: {
    display: "block",
    textAlign: "right",
    color: theme.palette.text.disabled,
  },
}));

interface CreateMedicalRecordDialogProps {
  suspicion: any;
  open: boolean;
  onClose: () => void;
}

export const CREATE_MEDICAL_RECORD_MUTATION = gql`
  mutation CreateMedicalRecord($medicalRecordInput: MedicalRecordInput!) {
    createMedicalRecord(medicalRecordInput: $medicalRecordInput) {
      id
      files
      comments
      confirmation {
        id
        status
      }
    }
  }
`;

export const MODIFY_MEDICAL_RECORD_MUTATION = gql`
  mutation ModifyMedicalRecord(
    $editMedicalRecordInput: EditMedicalRecordInput!
  ) {
    modifyMedicalRecord(editMedicalRecordInput: $editMedicalRecordInput) {
      id
      files
      comments
      confirmation {
        id
        status
      }
    }
  }
`;

const CreateMedicalRecordDialog: React.FC<CreateMedicalRecordDialogProps> = ({
  suspicion,
  open,
  onClose,
}) => {
  const [getCredentials, { called, loading: loadingCreds, data, error }] =
    useLazyQuery(
      gql`
        query getCredentials {
          generateS3AccessCredentials {
            AccessKeyId
            Arn
            AssumedRoleId
            Expiration
            PackedPolicySize
            SecretAccessKey
            SessionToken
          }
        }
      `,
      { fetchPolicy: "no-cache" }
    );
  const [createMedicalRecord, { loading }] = useMutation(
    suspicion.medicalRecord
      ? MODIFY_MEDICAL_RECORD_MUTATION
      : CREATE_MEDICAL_RECORD_MUTATION,
    {
      refetchQueries: [
        {
          query: SUSPICION_QUERY,
          variables: {
            suspicionId: suspicion.id,
          },
        },
      ],
    }
  );
  const { user }: any = useCurrentUser();
  const classes = useStyles();
  const [imgToRemove, setImgToRemove] = useState<any[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values: any) => {
    const response = await getCredentials();
    let files = [];
    for (const file of values.files) {
      if (typeof file !== "string") {
        files.push(
          await uploadFile(file, response?.data?.generateS3AccessCredentials)
        );
      }
    }
    try {
      if (suspicion.medicalRecord) {
        const newFiles = suspicion.medicalRecord.files.filter(
          (file: any) => !imgToRemove.includes(file)
        );
        await createMedicalRecord({
          variables: {
            editMedicalRecordInput: {
              ...values,
              id: suspicion.medicalRecord.id,
              files: [...newFiles, ...files],
            },
          },
        });
      } else {
        await createMedicalRecord({
          variables: {
            medicalRecordInput: {
              ...values,
              files,
            },
          },
        });
      }
      enqueueSnackbar("Resumen clínico / laboratorio ingresado exitosamente", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(
        "Hubo un error al ingresar el resumen clínico / laboratorio",
        {
          variant: "error",
        }
      );
    }
    onClose();
  };

  const removeImg = (file: any) => {
    if (!imgToRemove.includes(file)) {
      setImgToRemove([...imgToRemove, file]);
    }
  };

  return (
    <Formik
      initialValues={
        suspicion.medicalRecord
          ? {
              comments: suspicion.medicalRecord.comments,
            }
          : {
              comments: "",
              suspicionId: suspicion.id,
            }
      }
      onSubmit={handleSubmit}
      validationSchema={medicalRecordSchema}
    >
      {({ submitForm }) => (
        <Form>
          <ResponsiveDialog
            open={open}
            onClose={() => {
              onClose();
              setImgToRemove([]);
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Ingresar resumen clínico / laboratorio
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Completa el formulario con los datos
              </DialogContentText>
              <Box className={classes.filesContainer}>
                <Can
                  role={user.role}
                  perform={rules.laboratory.create}
                  yes={() => (
                    <FormDropzoneArea
                      name="files"
                      label="Laboratorio / Resumen Clínico"
                      // filesLimit={1}
                      // acceptedFiles={SUPPORTED_FORMATS}
                    />
                  )}
                />
                {/* <FormDropzoneArea
                  name="clinicalSummary"
                  label="Resumen Clínico"
                  // filesLimit={1}
                  // acceptedFiles={SUPPORTED_FORMATS}
                /> */}
              </Box>
              <Typography className={classes.extensions} variant="caption">
                Extensiones permitidas: pdf, jpg, png, xls, doc, xlsx, docx
              </Typography>
              <Box display="flex" style={{ gap: "10px" }} my="20px">
                {suspicion.medicalRecord?.files?.map((file: any) => {
                  if (imgToRemove.includes(file)) {
                    return null;
                  }
                  return (
                    <Box display="flex" flexDirection="column">
                      <Button onClick={() => removeImg(file)}>Eliminar</Button>
                      <img src={file} alt={file} width={120} />
                    </Box>
                  );
                })}
              </Box>
              <FormTextField
                name="comments"
                label="Comentarios (opcionales)"
                // multiline
                fullWidth
                // onFocus={(event: any) => event.stopPropagation()}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onClose();
                  setImgToRemove([]);
                }}
                color="secondary"
              >
                Cancelar
              </Button>
              <Button
                data-testid="enter-medical-record-button"
                onClick={submitForm}
                disabled={loading}
                color="primary"
                type="submit"
                variant="contained"
              >
                Ingresar
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        </Form>
      )}
    </Formik>
  );
};

export default CreateMedicalRecordDialog;
