const userRoles = [
  {
    value: 'user',
    label: 'Usuario',
  },
  {
    value: 'uciMedic',
    label: 'Médico UCI',
  },
  {
    value: 'procurer',
    label: 'Procurador',
  },
  {
    value: 'coordinator',
    label: 'Coordinador',
  },
  {
    value: 'admin',
    label: 'Administrador',
  },
] as const;

export default userRoles;