import { gql, QueryResult, useQuery } from '@apollo/client';

export const ALL_SURGERIES_QUERY = gql`
  query AllSurgeries {
    getSurgeries {
      id
      status
      createdAt
      crossClamp
      medicalCenter {
        id
        name
      }
      suspicion {
        id
        rut
      }
      scheduledAt
      updatedAt
    }
  }
`;

interface AllSurgeriesData {
  getSurgeries: Surgery[];
}

interface Surgery {
  id: string;
  status: string;
  createdAt: string;
  crossClamp: string;
  medicalCenter: {
    id: string;
    name: string;
  };
  suspicion: {
    id: string;
    rut: string;
  };
  scheduledAt: string;
  updatedAt: string;
}

export function useAllSurgeries(): QueryResult<AllSurgeriesData> {
  return useQuery<AllSurgeriesData>(ALL_SURGERIES_QUERY);
}
