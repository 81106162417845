import React, { useState, useMemo } from "react";
import {
  Grid,
  Typography,
  Paper,
  withStyles,
  Tab,
  Tabs,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { useAllOfferings } from "../features/offerings/use-all-offerings";
import OfferingsPanel from "../features/offerings/offerings-panel";
import FullPageSpinner from "../components/ui/full-page-spinner";
import { useCurrentUser } from "../features/authentication/use-current-user";
import { useCan } from "../utils/can";
import { rules } from "../utils/rbac-rules";

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    selected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  })
)(Tab);

const gridSpacing = 3;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      // top and bottom 24px padding + toolbar height + grid spacing + tabHeight
      height: `calc(100vh - ${theme.spacing(
        3 * 2 + 8 + gridSpacing + 4 + 6
      )}px)`,
      overflowY: "auto",
      overflowX: "hidden",
    },
    root: {
      flexGrow: 1,
    },
  })
);

const OfferingsPage: React.FC = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const { user } = useCurrentUser();
  const canManageOfferings = useCan({ perform: rules.offerings.manage });

  const { loading: currentLoading, ...data }: any = useAllOfferings();
  const currentOfferings = data?.data;

  const groupedData = currentOfferings?.reduce((groups: any, item: any) => {
    const organId = item.organ.id;
    if (!groups[organId]) {
      groups[organId] = [];
    }
    groups[organId].push(item);
    return groups;
  }, {});

  const lowestPriorityItems = Object.values(groupedData || {})
    .map((group: any) => {
      const pendingItems = group.filter(
        (item: any) => item.status === "Pendiente"
      );
      return pendingItems.reduce((minItem: any, currentItem: any) => {
        return currentItem.priority < minItem.priority ? currentItem : minItem;
      }, pendingItems[0]);
    })
    .filter((item: any) => !!item);

  const { loading: expiredLoading, ...dataExpired }: any = useAllOfferings({
    expired: true,
  });
  const expiredOfferings = dataExpired?.data;
  const medicalCenterIds = useMemo(
    () => user?.medicalCenters.map(({ id }) => id),
    [user]
  );

  const successfulOfferings = useMemo(
    () =>
      expiredOfferings?.filter(({ organ, accepted }: any) => {
        if (canManageOfferings) return accepted;
        return medicalCenterIds?.includes(organ.destinationMedicalCenter?.id);
      }),
    [canManageOfferings, expiredOfferings, medicalCenterIds]
  );

  const unsuccessfulOfferings = useMemo(
    () =>
      expiredOfferings?.filter(
        ({ organ, accepted, expired, rejected }: any) => {
          if (canManageOfferings) return rejected || (!accepted && expired);
          return !medicalCenterIds?.includes(
            organ.destinationMedicalCenter?.id
          );
        }
      ),
    [canManageOfferings, expiredOfferings, medicalCenterIds]
  );

  if (currentLoading || expiredLoading) return <FullPageSpinner />;

  return (
    <div className={classes.root}>
      <Grid container justifyContent="flex-start" spacing={gridSpacing}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div">
            Ofertas
          </Typography>
        </Grid>
        <Grid style={{ height: "100%" }} item lg={12} xl={10}>
          <Tabs value={tab} onChange={(_, value) => setTab(value)}>
            <StyledTab label="En curso" />
            <StyledTab label="Aceptadas" />
            <StyledTab label="Expiradas / Rechazadas" />
          </Tabs>
          <Paper className={classes.paper} elevation={3}>
            {tab === 0 && <OfferingsPanel offerings={lowestPriorityItems} />}
            {tab === 1 && (
              <OfferingsPanel expired offerings={successfulOfferings} />
            )}
            {tab === 2 && (
              <OfferingsPanel expired offerings={unsuccessfulOfferings} />
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default OfferingsPage;
