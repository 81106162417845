import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Divider from "@material-ui/core/Divider";
import { changeDateFormat } from "../../utils/date-format";
import CreateBackgroundButton from "./create-background-form";
import ConfirmBackgroundButton from "./confirm-background-dialog";
import { useCurrentUser } from "../authentication/use-current-user";
import Can from "../../utils/can";
import { rules } from "../../utils/rbac-rules";
import SuspicionAccordionSummary from "../suspicions/suspicion-expansion-panel-summary";

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, marginTop: theme.spacing(1) },
  detailTitle: {
    paddingLeft: theme.spacing(2),
    fontSize: "16px",
    color: theme.palette.text.secondary,
  },
  detailData: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  detailDataTime: {
    paddingLeft: theme.spacing(2),
    color: "gray",
  },
  detailUser: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: "16px",
  },
  detailNotConfirmed: {
    paddingLeft: theme.spacing(24),
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    fontSize: "16px",
  },
  detailDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

interface BackgroundDetailsProps {
  suspicion: any; // Adjust the type to match the actual data type
  isExpanded: boolean;
  onClick: () => void;
}

const BackgroundDetails: React.FC<BackgroundDetailsProps> = ({
  suspicion,
  isExpanded,
  onClick,
}) => {
  const classes = useStyles();
  const { user } = useCurrentUser();

  return (
    <>
      <div className={classes.root}>
        <Accordion expanded={isExpanded}>
          <SuspicionAccordionSummary
            label="Antecedentes Generales"
            onClick={onClick}
            isExpanded={isExpanded}
            suspicion={suspicion}
            stepName="background"
            testId="details-background-btn"
          >
            <Can
              role={user!.role}
              perform={rules?.background?.create}
              yes={() =>
                !suspicion?.background &&
                ((<CreateBackgroundButton suspicionInfo={suspicion} />) as any)
              }
            />
          </SuspicionAccordionSummary>
          {suspicion?.background ? (
            <AccordionDetails>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.detailDataTime}>
                    {changeDateFormat(suspicion.background.createdAt)}
                  </Typography>
                  <Typography className={classes.detailData}>
                    Antecedentes ingresados por:
                  </Typography>
                  <Typography className={classes.detailUser}>
                    {suspicion.background.creator.firstName}{" "}
                    {suspicion.background.creator.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {suspicion.background.confirmation?.status ? (
                    <>
                      <Typography className={classes.detailDataTime}>
                        {changeDateFormat(
                          suspicion.background.confirmation.updatedAt
                        )}
                      </Typography>
                      <Typography className={classes.detailData}>
                        Confirmados por:
                      </Typography>
                      <Typography className={classes.detailUser}>
                        {suspicion.background.confirmation.user.firstName}{" "}
                        {suspicion.background.confirmation.user.lastName}
                      </Typography>
                    </>
                  ) : (
                    <Typography className={classes.detailNotConfirmed}>
                      Por confirmar
                    </Typography>
                  )}
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.detailTitle}>Edad</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.detailData}>
                    {suspicion.background.age}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.detailTitle}>
                    Puntuación Glasgow
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.detailData}>
                    {suspicion.background.glasgow}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.detailTitle}>
                    Diagnóstico
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.detailData}>
                    {suspicion.background.diagnostic}
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.detailTitle}>
                    Comentarios adicionales
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.detailData}>
                    {suspicion.background.comment === ""
                      ? "Sin comentarios"
                      : suspicion.background.comment}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  justifyContent="flex-end"
                  style={{ display: "flex" }}
                >
                  <Can
                    role={user!.role}
                    perform={rules.background.confirm}
                    yes={() =>
                      !suspicion.background.confirmation?.status &&
                      ((
                        <ConfirmBackgroundButton
                          backgroundId={suspicion.background.id}
                          suspicionId={suspicion.id}
                        />
                      ) as any)
                    }
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          ) : null}
        </Accordion>
      </div>
    </>
  );
};

export default BackgroundDetails;
