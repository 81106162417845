

import React, { ReactNode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';


import store from './store';
import client from './apollo';
import theme from './theme';

import * as ReactDOM from 'react-dom/client';
import  ApolloProvider  from './apollo';


interface AppProvidersProps {
  children: ReactNode;
}



const AppProviders: React.FC<AppProvidersProps> = ({ children }) => {
  return (
    <ApolloProvider >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <SnackbarProvider maxSnack={1} autoHideDuration={3000}>
              <Router>{children}</Router>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default AppProviders;
