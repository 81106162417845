import { createSlice } from '@reduxjs/toolkit';
import { AUTHENTICATION_TOKEN_KEY } from '../../constants/index';

export const initialState = {
  token: localStorage.getItem(AUTHENTICATION_TOKEN_KEY),
  tokenExpired: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, { payload }) => {
      state.token = payload.token;
      state.tokenExpired = false;
    },
    logout: (state) => {
      state.token = null;
    },
    tokenExpired: (state) => {
      state.token = null;
      state.tokenExpired = true;
    },
  },
});

export const { login, logout, tokenExpired } = authSlice.actions;

export const selectIsAuthenticated = (state: any) =>
  state.auth.token && !state.auth.tokenExpired;

export default authSlice.reducer;
