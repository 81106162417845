import React from 'react';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
wrapper: {
position: 'relative',
margin: 8,
},
backgroundIcon: {
position: 'absolute',
top: '50%',
left: '50%',
width: '100%',
height: '100%',
borderRadius: '50%',
transform: 'translate(-50%, -50%)',
zIndex: theme.zIndex.appBar - 2,
backgroundColor: theme.palette.grey[100],
},
icon: {
position: 'absolute',
top: '50%',
left: '50%',
width: '60%',
height: '60%',
borderRadius: '50%',
zIndex: theme.zIndex.appBar - 1,
transform: 'translate(-50%, -50%)',
},
}));

interface StatusIconProps {
color: string;
size?: number;
}

const StatusIcon: React.FC<StatusIconProps> = ({ color, size = 16 }) => {
const classes = useStyles();
return (
<Box className={classes.wrapper} width={size} height={size}>
<Box className={classes.icon} style={{ backgroundColor: color }} />
<Box className={classes.backgroundIcon} />
</Box>
);
};

export default StatusIcon;