/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react';
import { differenceInMilliseconds } from 'date-fns';

const useCountdown = ({ targetDate = new Date(), refreshFrequency = 1000 } = {}) => {
  const [timeLeft, setTimeLeft] = useState(
    Math.max(differenceInMilliseconds(targetDate, new Date()), 0)
  );

  const refreshDate = useCallback(
    (newDate: any) => setTimeLeft(Math.max(differenceInMilliseconds(newDate, new Date()), 0)),
    []
  );

  useEffect(() => {
    if (timeLeft > 0) {
      const interval = setTimeout(
        () => setTimeLeft(Math.max(differenceInMilliseconds(targetDate, new Date()), 0)),
        refreshFrequency
      );

      return () => clearTimeout(interval);
    }

    return () => null;
  }, [targetDate, timeLeft]);

  return { timeLeft, refreshDate };
};

export default useCountdown;
