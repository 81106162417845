import { useMutation } from '@apollo/client';
import {
  Tooltip,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { gql } from 'apollo-boost';
import { Form, Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import FormTextField from '../../components/forms/form-text-field';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import Yup from '../../utils/validations';

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.error.light,
  },
  field: {
    marginTop: theme.spacing(1),
  },
}));

interface MedicalCenter {
  id: string;
  name: string;
}

interface ModifyMedicalCenterDialogProps {
  medicalCenterInfo: MedicalCenter;
}

interface EditMedicalCenterInput {
  id: string;
  name: string;
}

export const MODIFY_MEDICAL_CENTER_MUTATION = gql`
  mutation ModifyMedicalCenterMutation($editMedicalCenterInput: EditMedicalCenterInput!) {
    modifyMedicalCenter(editMedicalCenterInput: $editMedicalCenterInput) {
      id
      name
    }
  }
`;

const ModifyMedicalCenterDialog: React.FC<ModifyMedicalCenterDialogProps> = ({
  medicalCenterInfo,
}) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const [modifyMedicalCenter, { loading }] = useMutation(MODIFY_MEDICAL_CENTER_MUTATION);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSubmit = async (
    values: EditMedicalCenterInput,
    { resetForm }: FormikHelpers<EditMedicalCenterInput>
  ) => {
    try {
      await modifyMedicalCenter({
        variables: { editMedicalCenterInput: values },
      });
      enqueueSnackbar('Centro médico editado exitosamente', { variant: 'success' });
      resetForm({});
      closeModal();
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error, centro médico no fue editado exitosamente', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Tooltip title="Editar usuario">
        <IconButton
          aria-label="Editar usuario"
          color="primary"
          size="small"
          onClick={openModal}
          id="modify-medical-center-btn"
        >
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Formik
        initialValues={{
          id: medicalCenterInfo.id,
          name: medicalCenterInfo.name,
        }}
        validationSchema={Yup.object({
          name: Yup.string().required(),
        })}
        onSubmit={handleSubmit}
      >
        {({ submitForm }) => (
          <Form 
          // align="center"
          >
            <ResponsiveDialog
              open={isModalOpen}
              onClose={closeModal}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">Editar centro médico</DialogTitle>
              <DialogContent>
                <FormTextField
                  className={classes.field}
                  label="Nombre"
                  name="name"
                  fullWidth
                  required
                />
              </DialogContent>
              <br />
              <DialogActions>
                <Button onClick={closeModal} color="secondary">
                  Cancelar
                </Button>
                <Button
                  onClick={submitForm}
                  disabled={loading}
                  color="primary"
                  type="submit"
                >
                  Editar
                </Button>
              </DialogActions>
            </ResponsiveDialog>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ModifyMedicalCenterDialog;
