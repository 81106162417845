import React, { useState } from "react";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  makeStyles,
  Input,
} from "@material-ui/core";
import { ReportProblemOutlined as DangerIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import ResponsiveDialog from "../../components/ui/responsive-dialog";
import useModal from "../../hooks/useModal";
import { ALL_SUSPICIONS_QUERY } from "./use-all-suspicions";

export const DELETE_SUSPICION_MUTATION = gql`
  mutation DeleteSuspicionMutation($id: ID!, $discard: String!) {
    deleteSuspicion(id: $id, discard: $discard)
  }
`;

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

interface DenySuspicionButtonProps {
  suspicionInfo: {
    id: string;
    rut: string;
  };
}

const DenySuspicionButton: React.FC<DenySuspicionButtonProps> = ({
  suspicionInfo,
}) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [discard, setDiscard] = useState("");

  const [deleteSuspicion, { loading }] = useMutation(
    DELETE_SUSPICION_MUTATION,
    {
      optimisticResponse: null,
      update: (cache) => {
        const existingSuspicions = cache.readQuery({
          query: ALL_SUSPICIONS_QUERY,
        }) as any;
        const newSuspicions = existingSuspicions.getSuspicions.filter(
          (suspicion: any) => suspicion.id !== suspicionInfo.id
        );
        cache.writeQuery({
          query: ALL_SUSPICIONS_QUERY,
          data: { getSuspicions: newSuspicions },
        });
      },
    }
  );

  const handleSubmit = async () => {
    closeModal();
    history.push("/suspicions");

    try {
      await deleteSuspicion({
        variables: { id: suspicionInfo.id, discard },
      });
      enqueueSnackbar("Sospecha negada exitosamente", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Hubo un error al negar la sospecha", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Button
        size="small"
        variant="contained"
        onClick={openModal}
        id="delete-suspicion-btn"
        startIcon={<DangerIcon />}
        className={classes.button}
      >
        Descartar
      </Button>
      <ResponsiveDialog
        // align="center"
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Descartar sospecha del paciente {suspicionInfo?.rut}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer descartar la sospecha de manera permanente?
          </DialogContentText>
          <Input
            autoFocus
            id="discard"
            type="text"
            fullWidth
            placeholder="Motivo de descarte"
            multiline
            rows={3}
            onChange={(e) => setDiscard(e.target.value)}
            value={discard}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Volver
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading || !discard}
            color="primary"
            type="submit"
          >
            Confirmar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default DenySuspicionButton;
