import { gql, QueryResult, useQuery } from '@apollo/client';

export const OFFERINGS_QUERY = gql`
  query OfferingsQuery($expired: Boolean) {
    getOfferings(expired: $expired) {
      id
      priority
      status
      accepted
      rejected
      expired
      createdAt
      activeDate
      medicalCenter {
        name
      }
      organ {
        id
        destinationMedicalCenter {
          id
        }
        suspicion {
          id
          rut
        }
        organType {
          id
          name
        }
      }
    }
  }
`;

interface Offering {
  id: string;
  priority: number;
  status: string;
  accepted: boolean;
  rejected: boolean;
  expired: boolean;
  createdAt: string;
  medicalCenter: {
    name: string;
  };
  organ: {
    id: string;
    destinationMedicalCenter: {
      id: string;
    };
    suspicion: {
      id: string;
      rut: string;
    };
    organType: {
      id: string;
      name: string;
    };
  };
}

interface OfferingsQueryData {
  getOfferings: Offering[];
}

interface OfferingsQueryVariables {
  expired?: boolean;
}

export const useAllOfferings = ({
  expired = false,
}: OfferingsQueryVariables = {}): any => {
  const { data, loading, error } = useQuery<OfferingsQueryData, OfferingsQueryVariables>(
    OFFERINGS_QUERY,
    {
      variables: expired ? { expired } : undefined,
    }
  );

  return { data: data?.getOfferings, loading, error };
};
