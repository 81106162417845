import { Button, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import useModal from '../../hooks/useModal';
import ScheduleCrossClampDialog from './schedule-cross-clamp-dialog';
import { useCan } from '../../utils/can';
import { rules } from '../../utils/rbac-rules';

const useStyles = makeStyles((theme) => ({
  scheduleCrossClampButton: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

interface ScheduleCrossClampButtonProps {
  surgery: any;
  onProcura:any;
  onCrossClamp:any;
}

const ScheduleCrossClampButton: React.FC<ScheduleCrossClampButtonProps> = ({
  surgery,
  onProcura,
  onCrossClamp,
}) => {
  const canCrossClamp = useCan({ perform: rules.surgery.crossClamp });
  const classes = useStyles();
  const { isModalOpen, open: openModal, close: closeModal } = useModal();

  const isProcura = useMemo(() => {
    const hasProcura = surgery.organs.some((item: any) => {
      return !item.startProcure
    })
    return hasProcura
  }, [surgery.organs])

  const isCrossClamp = useMemo(() => {
    const hasCross = surgery.organs.some((item: any) => {
      return !item.crossClamp
    })
    return hasCross;
  }, [surgery.organs])
  
  return (
    <>
      {isProcura && (
        <Button
          className={classes.scheduleCrossClampButton}
          onClick={(event) => {
            event.stopPropagation();
            onProcura();
          }}
          variant="contained"
          color="primary"
          size="small"
          data-testid="schedule-cross-clamp-button"
        >
          Inicio de Procura
        </Button>
      )}

      {!isProcura && isCrossClamp && (

        <Button
          className={classes.scheduleCrossClampButton}
          onClick={(event) => {
            event.stopPropagation();
            openModal();
          }}
          variant="contained"
          color="primary"
          size="small"
          data-testid="schedule-cross-clamp-button"
        >
          Inicio de Cross Clamp
        </Button>

      )}
      <ScheduleCrossClampDialog
        surgery={surgery}
        open={isModalOpen}
        onCrossClamp={onCrossClamp}
        onClose={closeModal}
      />
    </>
  )
};

export default ScheduleCrossClampButton;
