import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  Tooltip,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Container,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import  Yup from '../../utils/validations';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import FormTextField from '../../components/forms/form-text-field';
import { ALL_MEDICAL_CENTERS_QUERY } from './use-all-medical-centers';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: 275,
  },
}));

interface CreateMedicalCenterMutationResponse {
  createMedicalCenter: {
    id: string;
    name: string;
  };
}

interface CreateMedicalCenterMutationVariables {
  name: string;
}

const CREATE_MEDICAL_CENTER_MUTATION = gql`
  mutation CreateMedicalCenterMutation($name: String!) {
    createMedicalCenter(name: $name) {
      id
      name
    }
  }
`;

const createMedicalCenterSchema = Yup.object({
  name: Yup.string().required(),
});

const CreateUserButton: React.FC = () => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const [createMedicalCenter, { loading }] = useMutation<
    CreateMedicalCenterMutationResponse,
    CreateMedicalCenterMutationVariables
  >(CREATE_MEDICAL_CENTER_MUTATION, {
    update: (cache, { data }) => {
      const createdMedicalCenter = data?.createMedicalCenter;
      if (createdMedicalCenter) {
        const { allMedicalCenters: existingMedicalCenters }: any = cache.readQuery({
          query: ALL_MEDICAL_CENTERS_QUERY,
        });
        cache.writeQuery({
          query: ALL_MEDICAL_CENTERS_QUERY,
          data: {
            allMedicalCenters: [...existingMedicalCenters, createdMedicalCenter],
          },
        });
      }
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSubmit = async (values: CreateMedicalCenterMutationVariables, { resetForm }: any) => {
    try {
      await createMedicalCenter({
        variables: values,
      });
      enqueueSnackbar('Centro médico creado exitosamente', { variant: 'success' });
      resetForm({});
      closeModal();
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error, centro médico no fue creado exitosamente', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Tooltip title="Crear centro médico">
        <Button
          aria-label="Crear centro médico"
          color="primary"
          onClick={openModal}
          id="create-medical-center-btn"
        >
          <AddCircleIcon />
        </Button>
      </Tooltip>
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={createMedicalCenterSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm }) => (
          <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
              <Form className={classes.form}>
                <ResponsiveDialog
                  open={isModalOpen}
                  onClose={closeModal}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">Crear nuevo centro médico</DialogTitle>
                  <DialogContent>
                    <Grid item xs={12} sm={12}>
                      {/* <FormTextField label="Nombre" name="name" required fullWidth /> */}
                    </Grid>
                  </DialogContent>
                  <br />
                  <DialogActions>
                    <Button onClick={closeModal} color="secondary">
                      Cancelar
                    </Button>
                    <Button onClick={submitForm} disabled={loading} color="primary" type="submit">
                      Registrar
                    </Button>
                  </DialogActions>
                </ResponsiveDialog>
              </Form>
            </div>
          </Container>
        )}
      </Formik>
    </>
  );
};

export default CreateUserButton;
