import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { gql } from "apollo-boost";
import { Form, Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import FormDropzoneArea from "../../components/forms/form-dropzone-area";
import FormTextField from "../../components/forms/form-text-field";
import ResponsiveDialog from "../../components/ui/responsive-dialog";
import Yup from "../../utils/validations";
import { SUSPICION_QUERY } from "../suspicions/use-suspicion";
import { uploadFile } from "../../utils/aws";

interface ConfirmBrainDeathDialogProps {
  suspicionId: string;
  open: boolean;
  onClose: () => void;
}

interface ConfirmBrainDeathInput {
  document: File | null;
  comments?: string;
  suspicionId: string;
}

const SUPPORTED_FORMATS = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  ".xls",
  ".xlsx",
  ".doc",
  ".docx",
];

const confirmBrainDeathSchema = Yup.object({
  comments: Yup.string().optional(),
  suspicionId: Yup.number(),
});

export const CREATE_BRAIN_DEATH_MUTATION = gql`
  mutation CreateBrainDeath($brainDeathInput: BrainDeathInput!) {
    createBrainDeath(brainDeathInput: $brainDeathInput) {
      id
      document
      comments
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  extensions: {
    display: "block",
    textAlign: "right",
    color: theme.palette.text.disabled,
  },
}));

const ConfirmBrainDeathDialog: React.FC<ConfirmBrainDeathDialogProps> = ({
  suspicionId,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const [getCredentials, { called, loading: loadingCreds, data, error }] =
    useLazyQuery(
      gql`
        query getCredentials {
          generateS3AccessCredentials {
            AccessKeyId
            Arn
            AssumedRoleId
            Expiration
            PackedPolicySize
            SecretAccessKey
            SessionToken
          }
        }
      `,
      { fetchPolicy: "no-cache" }
    );
  const [confirmBrainDeath, { loading }] = useMutation(
    CREATE_BRAIN_DEATH_MUTATION,
    {
      refetchQueries: [
        {
          query: SUSPICION_QUERY,
          variables: {
            suspicionId,
          },
        },
      ],
    }
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (
    values: ConfirmBrainDeathInput,
    { resetForm }: FormikHelpers<ConfirmBrainDeathInput>
  ) => {
    const response = await getCredentials();
    let document;
    if (values.document) {
      document = await uploadFile(
        values.document,
        response?.data?.generateS3AccessCredentials
      );
    }
    try {
      await confirmBrainDeath({
        variables: {
          brainDeathInput: {
            ...values,
            document,
          },
        },
      });
      enqueueSnackbar("Confirmación de muerte cerebral creada exitosamente", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Hubo un error al procesar su solicitud.", {
        variant: "error",
      });
    }
    resetForm({});
    onClose();
  };

  return (
    <Formik
      initialValues={{
        document: null,
        comments: "",
        suspicionId,
      }}
      onSubmit={handleSubmit as any}
      validationSchema={confirmBrainDeathSchema}
    >
      {({ submitForm }) => (
        <Form>
          <ResponsiveDialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Confirmar muerte cerebral
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Completa el formulario con los datos.
              </DialogContentText>
              <FormDropzoneArea name="document" label="Documento" />
              <Typography className={classes.extensions} variant="caption">
                Extensiones permitidas: pdf, jpg, png, xls, doc, xlsx, docx
              </Typography>
              <FormTextField
                name="comments"
                label="Comentarios"
                multiline
                fullWidth
                onFocus={(event: any) => event.stopPropagation()}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancelar
              </Button>
              <Button
                onClick={submitForm}
                disabled={loading}
                color="primary"
                type="submit"
                variant="contained"
              >
                Ingresar
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        </Form>
      )}
    </Formik>
  );
};

export default ConfirmBrainDeathDialog;
