import { Paper, Grid } from '@material-ui/core/';
import React, { useMemo } from 'react';
import { Column } from 'react-table';
import { SelectFilter, TextFilter } from '../../components/table/filters';
import EnhancedTable from '../../components/ui/enhanced-table';
import DeleteUserButton from './delete-user-button';
import ModifyUserButton from './modify-user-button';

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

interface UsersTableProps {
  users: User[];
}

const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  const data = useMemo(() => users, [users]);
  const columns: Column<User>[] = useMemo(
    () => [
      {
        label: 'NOMBRE',
        accessor: 'firstName',
        Filter: TextFilter,
      },
      {
        label: 'APELLIDO',
        accessor: 'lastName',
        Filter: TextFilter,
      },
      {
        label: 'CORREO',
        accessor: 'email',
        Filter: TextFilter,
      },
      {
        label: 'ROL',
        accessor: 'role',
        Filter: SelectFilter,
        disableSortBy: true,
      },
      {
        Header: 'ACCIONES',
        id: 'actions',
        accessor: 'id',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row, value }: any) => {
          return (
            <Grid container justifyContent="space-evenly">
              <Grid item xs={3} >
                <ModifyUserButton userInfo={row.original} />
              </Grid>
              <Grid item xs={3} >
                <DeleteUserButton userId={value} />
              </Grid>
            </Grid>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Paper elevation={0}>
        <EnhancedTable<User> data={data} columns={columns} />
      </Paper>
    </>
  );
};

export default UsersTable;
