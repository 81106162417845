import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { ExitToApp as AddIcon } from '@material-ui/icons';

import useModal from '../../hooks/useModal';
import ConfirmBrainDeathDialog from './confirm-brain-death-dialog';

interface ConfirmBrainDeathButtonProps extends ButtonProps {
  suspicionId: string;
  expanded?: boolean;
  children: React.ReactNode;
}

const ConfirmBrainDeathButton: React.FC<ConfirmBrainDeathButtonProps> = ({
  suspicionId,
  expanded,
  children,
  ...buttonProps
}) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();

  return (
    <>
      <Button
        onClick={openModal}
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        {...buttonProps}
      >
        {children}
      </Button>
      <ConfirmBrainDeathDialog
        suspicionId={suspicionId}
        open={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default ConfirmBrainDeathButton;