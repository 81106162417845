import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack';
import VerticalTypographicLogoIconComponent from '../components/ui/vertical-logo';
import LoginForm from '../features/authentication/login-form';

const useStyles = makeStyles((theme) => ({
page: {
marginTop: theme.spacing(6),
display: 'flex',
flexDirection: 'column',
alignItems: 'center',
},
paper: {
marginTop: theme.spacing(2),
height: 375,
},
loginForm: {
marginLeft: theme.spacing(1),
},
}));

const LoginPage: React.FC = () => {
const { tokenExpired } = useSelector((state: any) => state.auth);
const { enqueueSnackbar } = useSnackbar();
const classes = useStyles();

useEffect(() => {
if (tokenExpired) {
enqueueSnackbar('Su sesión ha finalizado. Ingrese nuevamente', {
variant: 'error',
});
}
}, [enqueueSnackbar, tokenExpired]);

useEffect(() => {
localStorage.clear();
}, []);

return (
<Container component="main" maxWidth="xs">
<div className={classes.page}>
<VerticalTypographicLogoIconComponent />
<Paper elevation={3} className={classes.paper}>
<LoginForm  />
</Paper>
</div>
</Container>
);
};

export default LoginPage;






