import { useState } from 'react';

interface ModalOptions {
initialOpen?: boolean;
}

interface ModalResult {
isModalOpen: boolean;
close: () => void;
open: () => void;
}

function useModal({ initialOpen = false }: ModalOptions = {}): ModalResult {
const [isModalOpen, setIsModalOpen] = useState(initialOpen);
const close = () => setIsModalOpen(false);
const open = () => setIsModalOpen(true);

return { isModalOpen, close, open };
}

export default useModal;