export const suspicionStatus = {
  initial: 'Sospecha',
  active: 'Activo',
  confirmed: 'Confirmado',
  allocated: 'Alocación',
  surgery: 'Cirugía',
  transplanted: 'Trasplantado',
};

export const offeringStatus = {
  accepted: 'Aceptada',
  dismissed: 'Rechazada',
  pending: 'Pendiente',
  expired: 'Expirada',
};

export const organStatus = {
  pending: 'Pendiente',
  beingAllocated: 'Alocando',
  assigned: 'Asignado',
  transplanted: 'Trasplantado',
  rejected: 'Desestimado',
  startProcure: 'InicioProcura',
  crossClamp: 'CrossClamp',
  endProcure: 'FinalProcura'
};

export const surgeryStatus = {
  scheduled: 'Agendada',
  ongoing: 'En curso',
  onIschemia: 'En isquemia',
  done: 'Realizada',
  
};
