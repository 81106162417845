import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Box,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import FormCheckboxField from "../../components/forms/form-checkbox-field";
import FormDropzoneArea from "../../components/forms/form-dropzone-area";
import FormTextField from "../../components/forms/form-text-field";
import ResponsiveDialog from "../../components/ui/responsive-dialog";
import Yup from "../../utils/validations";
import { SUSPICION_QUERY } from "../suspicions/use-suspicion";
import { CREATE_INCLUSION_CRITERIA_MUTATION } from "./use-create-inclusion-criteria";
import gql from "graphql-tag";
import { uploadFile } from "../../utils/aws";
import { event } from "jquery";
const SUPPORTED_FORMATS = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  ".xls",
  ".xlsx",
  ".doc",
  ".docx",
];

const inclusionCriteriaSchema = Yup.object({
  vih: Yup.mixed(),
  vhb: Yup.mixed(),
  vhc: Yup.mixed(),
  hasSepsis: Yup.boolean().required(),
  hasCancer: Yup.boolean().required(),
  comments: Yup.string().optional(),
  suspicionId: Yup.number().required(),
});

const useStyles = makeStyles((theme) => ({
  filesContainer: {
    display: "flex",
    "& > *:not(:last-child)": { margin: theme.spacing(0, 1, 0, 0) },
  },
  extensions: {
    display: "block",
    textAlign: "right",
    color: theme.palette.text.disabled,
  },
  boxTable: {
    width: "100%",
  },
  textHeaderTable: {
    textAlign: "left",
    width: "20%",
  },
  textTableHeader: {
    marginLeft: "15px",
  },
  headerTableImage: {
    width: "40%",
  },
  rowTable: {
    height: "90px",
  },
}));

interface CreateInclusionCriteriaDialogProps {
  suspicion: any;
  open: boolean;
  onClose: () => void;
}

const CreateInclusionCriteriaDialog: React.FC<
  CreateInclusionCriteriaDialogProps
> = ({ suspicion, open, onClose }) => {
  const [getCredentials, { called, loading: loadingCreds, data, error }] =
    useLazyQuery(
      gql`
        query getCredentials {
          generateS3AccessCredentials {
            AccessKeyId
            Arn
            AssumedRoleId
            Expiration
            PackedPolicySize
            SecretAccessKey
            SessionToken
          }
        }
      `,
      { fetchPolicy: "no-cache" }
    );
  const [createInclusionCriteria, { loading }] = useMutation(
    CREATE_INCLUSION_CRITERIA_MUTATION,
    {
      refetchQueries: [
        {
          query: SUSPICION_QUERY,
          variables: {
            suspicionId: suspicion.id,
          },
        },
      ],
    }
  );
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [vihCheckBox, setVihCheckbox] = useState(false);
  const [vhcCheckbox, setVhcCheckbox] = useState(false);
  const [vhbCheckbox, setVhbCheckbox] = useState(false);
  const [sepsisCheckbox, setSepsisCheckbox] = useState(false);
  const [cancerCheckbox, setCancerCheckbox] = useState(false);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    
    const response = await getCredentials();
    let vih, vhb, vhc;
    if (values.vih) {
      vih = await uploadFile(
        values.vih,
        response?.data?.generateS3AccessCredentials
      );
    }
    if (values.vhb) {
      vhb = await uploadFile(
        values.vhb,
        response?.data?.generateS3AccessCredentials
      );
    }
    if (values.vhc) {
      vhc = await uploadFile(
        values.vhc,
        response?.data?.generateS3AccessCredentials
      );
    }
    resetForm({});
    onClose();
    try {
      await createInclusionCriteria({
        variables: {
          inclusionCriteriaInput: {
            ...values,
            vih,
            vhb,
            vhc,
            hasVih: vihCheckBox,
            hasVhb: vhbCheckbox,
            hasVhc: vhcCheckbox,
            hasSepsis: sepsisCheckbox,
            hasCancer: cancerCheckbox,
          },
        },
      });
      enqueueSnackbar("Criterio de inclusión creado exitosamente", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Hubo un error al procesar su solicitud.", {
        variant: "error",
      });
    }
  };

  return (
    <Formik
      initialValues={{
        vhb: null,
        vhc: null,
        vih: null,
        hasCancer: false,
        hasSepsis: false,
        comments: "",
        suspicionId: suspicion.id,
      }}
      onSubmit={handleSubmit}
      validationSchema={inclusionCriteriaSchema}
    >
      {({ submitForm, values }: any) => {
        return (
        <Form>
          <ResponsiveDialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Agregar criterio de inclusión
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Completa el formulario con los datos.
              </DialogContentText>
              <div className={classes.boxTable}>
                <table className={classes.boxTable}>
                  <thead>
                    <tr>
                      <th className={classes.textHeaderTable}>Criterio</th>
                      <th className={classes.textHeaderTable}>
                        <p className={classes.textTableHeader}>
                          Si
                        </p>
                      </th>
                      <th className={classes.textHeaderTable}>
                        <p className={classes.textTableHeader}>
                          No
                        </p>
                        </th>
                      <th className={classes.headerTableImage}>Archivo</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr className={classes.rowTable}>
                      <td>VIH</td>
                      <td>
                        <Checkbox
                          color="primary"
                          checked={vihCheckBox}
                          onChange={(e) => {
                            setVihCheckbox(e.target.checked);
                          }}
                        />
                      </td>
                      <td>
                        <Checkbox
                          color="primary"
                          checked={!vihCheckBox}
                          onChange={(e) => {
                            setVihCheckbox(!e.target.checked);
                          }}
                        />
                      </td>

                      <td>
                        <FormDropzoneArea name="vih" label="VIH" />
                      </td>
                    </tr>
                    <tr className={classes.rowTable}>
                      <td>VHC</td>
                      <td>
                        <Checkbox
                          color="primary"
                          checked={vhcCheckbox}
                          onChange={(e) => {
                            setVhcCheckbox(e.target.checked);
                          }}
                        />
                      </td>
                      <td>
                        <Checkbox
                          color="primary"
                          checked={!vhcCheckbox}
                          onChange={(e) => {
                            setVhcCheckbox(!e.target.checked);
                          }}
                        />
                      </td>

                      <td>
                        <FormDropzoneArea name="vhc" label="VHC" />
                      </td>
                    </tr>
                    <tr className={classes.rowTable}>
                      <td>VHB</td>
                      <td>
                        <Checkbox
                          color="primary"
                          checked={vhbCheckbox}
                          onChange={(e) => {
                            setVhbCheckbox(e.target.checked);
                          }}
                        />
                      </td>
                      <td>
                        <Checkbox
                          color="primary"
                          checked={!vhbCheckbox}
                          onChange={(e) => {
                            setVhbCheckbox(!e.target.checked);
                          }}
                        />
                      </td>

                      <td>
                        <FormDropzoneArea name="vhb" label="VHB" />
                      </td>
                    </tr>
                    <tr className={classes.rowTable}>
                      <td>SEPSIS</td>
                      <td>
                        <Checkbox
                          color="primary"
                          checked={sepsisCheckbox}
                          onChange={(e) => {
                            setSepsisCheckbox(e.target.checked);
                          }}
                        />
                      </td>
                      <td>
                        <Checkbox
                          color="primary"
                          checked={!sepsisCheckbox}
                          onChange={(e) => {
                            setSepsisCheckbox(!e.target.checked);
                          }}
                        />
                      </td>

                      <td></td>
                    </tr>
                    <tr className={classes.rowTable}>
                      <td>CANCER</td>
                      <td>
                        <Checkbox
                          color="primary"
                          checked={cancerCheckbox}
                          onChange={(e) => {
                            setCancerCheckbox(e.target.checked);
                          }}
                        />
                      </td>
                      <td>
                        <Checkbox
                          color="primary"
                          checked={!cancerCheckbox}
                          onChange={(e) => {
                            setCancerCheckbox(!e.target.checked);
                          }}
                        />
                      </td>

                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <FormTextField
                name="comments"
                label="Comentarios"
                // multiline
                fullWidth
                // onFocus={(event: React.FocusEvent<HTMLInputElement>) =>
                //   event.stopPropagation()
                // }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancelar
              </Button>
              <Button
                onClick={submitForm}
                disabled={loading}
                color="primary"
                type="submit"
                variant="contained"
              >
                Ingresar
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        </Form>
      )}}
    </Formik>
  );
};

export default CreateInclusionCriteriaDialog;
