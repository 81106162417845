import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { SelectFilter, TextFilter } from "../../components/table/filters";
import EnhancedTable from "../../components/ui/enhanced-table";
import { changeDateFormatShort } from "../../utils/date-format";

interface SuspicionsTableProps {
  suspicions: any[]; // Replace 'any' with the actual type of 'suspicions' data
}

const SuspicionsTable: React.FC<SuspicionsTableProps> = ({ suspicions }) => {
  const data = useMemo(() => suspicions, [suspicions]);
  console.log("🚀 ~ file: suspicions-table.tsx:13 ~ data:", data);

  const columns = useMemo(
    () => [
      {
        label: "RUT",
        accessor: "rut",
        disableSortBy: true,
        Filter: TextFilter,
      },
      {
        label: "ESTADO",
        accessor: "status",
        Filter: SelectFilter,
        disableSortBy: true,
      },
      {
        Header: "ÚLTIMA ACTUALIZACIÓN",
        accessor: "updatedAt",
        disableFilters: true,
        disableSortBy: false,
        Cell: ({ value }: any) => changeDateFormatShort(value),
      },
      {
        label: "ESTABLECIMIENTO",
        accessor: "medicalCenter.name",
        disableSortBy: true,
        Filter: TextFilter,
      },
    ],
    []
  );

  const initialState = useMemo(
    () => ({
      sortBy: [
        {
          id: "updatedAt",
          desc: true,
        },
      ],
    }),
    []
  );

  const history = useHistory();

  return (
    <EnhancedTable
      data={data}
      columns={columns}
      initialState={initialState}
      clickableRow
      onClickRow={(row: any) => history.push(`/suspicions/${row.original.id}`)}
    />
  );
};

export default SuspicionsTable;
