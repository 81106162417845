import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/client';
import {
  Tooltip,
  Button,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@material-ui/icons/Delete';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { ALL_MEDICAL_CENTERS_QUERY } from './use-all-medical-centers';

const DELETE_MUTATION = gql`
  mutation DeleteMedicalCenterMutation($id: ID!) {
    deleteMedicalCenter(id: $id)
  }
`;

interface DeleteMedicalCenterDialogProps {
  medicalCenterId: string;
}

const DeleteMedicalCenterDialog: React.FC<DeleteMedicalCenterDialogProps> = ({
  medicalCenterId,
}) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();

  const { enqueueSnackbar } = useSnackbar();

  const [deleteMedicalCenter, { loading }] = useMutation(DELETE_MUTATION, {
    optimisticResponse: null,
    update: (cache) => {
      const medicalCentersQuery = cache.readQuery<any>({
        query: ALL_MEDICAL_CENTERS_QUERY,
      });
      const newMedicalCenters = medicalCentersQuery.allMedicalCenters.filter(
        (h: any) => h.id !== medicalCenterId
      );
      cache.writeQuery({
        query: ALL_MEDICAL_CENTERS_QUERY,
        data: { allMedicalCenters: newMedicalCenters },
      });
    },
  });
  const handleSubmit = async () => {
    closeModal();
    try {
      await deleteMedicalCenter({
        variables: { id: medicalCenterId },
      });
      enqueueSnackbar('Centro médico eliminado exitosamente', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Hubo un error al procesar su solicitud', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Tooltip title="Eliminar centro médico">
        <IconButton
          aria-label="Eliminar centro médico"
          size="small"
          color="primary"
          onClick={openModal}
          id="delete-medical-center-btn"
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ResponsiveDialog
        // align="center"
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirmar eliminación de centro médico
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer eliminar el centro médico y sus datos asociados de manera permanente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
          >
            Eliminar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default DeleteMedicalCenterDialog;
