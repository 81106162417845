import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Redirect, useParams } from "react-router-dom";

import FullPageSpinner from "../components/ui/full-page-spinner";
import { useCurrentUser } from "../features/authentication/use-current-user";
import { useSurgery } from "../features/surgeries/use-surgery";
import Can from "../utils/can";
import { rules } from "../utils/rbac-rules";
import SurgeryBar from "../features/surgeries/surgery-bar";
import OrgansPanel from "../features/surgeries/organs-panel";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content min-content 1fr",
    gridGap: theme.spacing(3),
    gridTemplateAreas: "title surgery panels",
  },
  surgery: { gridArea: "surgery", marginBottom: '50px' },
  panels: { gridArea: "panels" },
  title: {
    gridArea: "title",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    fontWeight: 500,
    marginBottom: '30px'
  },
  surgeryInfo: { paddingRight: theme.spacing(3) },
  crossClampButton: {
    marginTop: theme.spacing(5),
  },
}));

interface RouteParams {
  id: string;
}

const SurgeryDetailsPage: React.FC = () => {
  const { id: surgeryId } = useParams<RouteParams>();
  const classes = useStyles();
  const {
    data: { getSurgery: surgery = {} } = {},
    loading,
    ...rest
  }: any = useSurgery(surgeryId);
  console.log("🚀 ~ file: surgery-details-page.tsx:43 ~ surgery:", rest);
  const { user } = useCurrentUser();

  if (loading) return <FullPageSpinner />;

  return (
    <Can
      role={user!.role}
      perform={rules.surgery.get}
      no={() => <Redirect to="/surgeries/" />}
      yes={() => (
        <Box >
          <Typography className={classes.title}>Cirugía en curso</Typography>
          <Box className={classes.surgery}>
            <SurgeryBar surgery={surgery} />
          </Box>
          <Box className={classes.panels}>
            <OrgansPanel surgery={surgery} />
          </Box>
        </Box>
      )}
    />
  );
};

export default SurgeryDetailsPage;
