import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/client';
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { addHours } from 'date-fns';
import Yup from '../../utils/validations';

import { useSnackbar } from 'notistack';

import ResponsiveDialog from '../../components/ui/responsive-dialog';
import FormDateTimePicker from '../../components/forms/form-datetime-picker';
import { CONFIRMED_SUSPICIONS_QUERY } from './use-confirmed-suspicions';
import { ALL_SURGERIES_QUERY } from '../surgeries/use-all-surgeries';

const useStyles = makeStyles((theme) => ({
  content: { margin: theme.spacing(1, 0) },
}));

const SCHEDULE_SURGERY_MUTATION = gql`
  mutation ScheduleSurgery($suspicionId: ID!, $scheduleDate: Date!) {
    scheduleSurgery(suspicionId: $suspicionId, scheduleDate: $scheduleDate) {
      id
    }
  }
`;

interface ScheduleSurgeryDialogProps {
  suspicion: any; // Adjust the type of suspicion accordingly
  open: boolean;
  onClose: () => void;
}

const ScheduleSurgeryDialog: React.FC<ScheduleSurgeryDialogProps> = ({ suspicion, open, onClose }) => {
  const [scheduleSurgery, { loading }] = useMutation(SCHEDULE_SURGERY_MUTATION, {
    refetchQueries: [
      { query: CONFIRMED_SUSPICIONS_QUERY },
      { query: ALL_SURGERIES_QUERY },
    ],
  });

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values: { scheduleDate: Date }, { resetForm }: any) => {
    try {
      await scheduleSurgery({ variables: { ...values, suspicionId: suspicion.id } });
      enqueueSnackbar('Cirugía agendada correctamente', { variant: 'success' });
      onClose();
      resetForm({});
    } catch (err) {
      enqueueSnackbar('Ha ocurrido un error agendando la cirugía', { variant: 'error' });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ scheduleDate: addHours(new Date(), 2) }}
      validationSchema={Yup.object({
        scheduleDate: Yup.date().min(new Date()).required(),
      })}
    >
      {({ submitForm }) => (
        <Form>
          <ResponsiveDialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
            onClick={(event) => event.stopPropagation()}
          >
            <DialogTitle>Agendar cirugía de paciente {suspicion.rut}</DialogTitle>
            <DialogContent className={classes.content}>
              <FormDateTimePicker
                label="Fecha y hora"
                name="scheduleDate"
                // disablePast
                // fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => onClose()}>Volver</Button>
              <Button
                onClick={() => submitForm()}
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Agendar
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        </Form>
      )}
    </Formik>
  );
};

export default ScheduleSurgeryDialog;
