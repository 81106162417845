import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { ApolloProvider } from "@apollo/client/react/context";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/client/react";
import { createUploadLink } from "apollo-upload-client";

import { AUTHENTICATION_TOKEN_KEY } from "../constants";

const httpLink = new HttpLink({
  uri: "https://api.trasplan.com/graphql",
  // uri: "http://localhost:8080/graphql",
});

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(AUTHENTICATION_TOKEN_KEY);
  const authorizationHeader = token;

  if (token) {
    const currentContext = operation.getContext();
    operation.setContext({
      ...currentContext,
      headers: {
        ...currentContext.headers,
        authorization: `Bearer ${authorizationHeader}`,
      },
    });
  }

  return forward(operation);
});

const middlewareLangLink = new ApolloLink((operation, forward) => {
  const lang = localStorage.getItem("lang");
  const currentContext = operation.getContext();
  operation.setContext({
    ...currentContext,
    headers: {
      ...currentContext.headers,
      lang: lang || "es",
    },
  });

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message.match(/(invalid signature|jwt)/i)) {
        localStorage.removeItem(AUTHENTICATION_TOKEN_KEY);
      }
    });
  }
});

const httpLinkWithAuthToken = ApolloLink.from([
  middlewareAuthLink,
  middlewareLangLink,
  errorLink,
  httpLink,
  createUploadLink({
    headers: {
      "Apollo-Require-Preflight": "true",
    },
  }),
]);

const client = new ApolloClient({
  link: httpLinkWithAuthToken,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "cache-first",
    },
  },
});

const ApolloWrapper = ({ children }: any) => {
  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>{children}</ApolloHooksProvider>
    </ApolloProvider>
  );
};

export default ApolloWrapper;
