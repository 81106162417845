import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import FullPageSpinner from "../components/ui/full-page-spinner";
import SurgeriesTable from "../features/surgeries/surgeries-table";
import { useAllSurgeries } from "../features/surgeries/use-all-surgeries";
import { useCan } from "../utils/can";
import { rules } from "../utils/rbac-rules";

const SurgeriesPage: React.FC = () => {
  const { loading, ...data }: any = useAllSurgeries();
  const surgeries = data?.data?.getSurgeries;
  const canGet = useCan({ perform: rules.surgery.get });

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      {canGet && (
        <Box>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h6" component="div">
              Cirugías
            </Typography>
          </Box>
          <Paper elevation={0}>
            <SurgeriesTable surgeries={surgeries} />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default SurgeriesPage;
