import React from "react";
import { Box, makeStyles } from "@material-ui/core";

import Can from "../utils/can";
import CreateSuspicionButton from "../features/suspicions/create-suspicion-button";
import { rules } from "../utils/rbac-rules";
import { useCurrentUser } from "../features/authentication/use-current-user";

const useStyles = makeStyles((theme) => ({
  main: {
    // top and bottom 24px padding + toolbar height
    height: `calc(100vh - ${theme.spacing(3 * 2 + 8)}px)`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const HomePage: React.FC = () => {
  const { user } = useCurrentUser();
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Can
        role={user?.role ?? ""}
        perform={rules.suspicions.create}
        yes={() => <CreateSuspicionButton />}
      />
    </Box>
  );
};

export default HomePage;
