import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Hidden as HiddenMui,
  useTheme,
} from "@material-ui/core";
import {
  Home as HomeIcon,
  Error as ErrorIcon,
  ExitToApp as ExitIcon,
  Group as GroupIcon,
  LocalHospital as LocalHospitalIcon,
  Favorite as FavoriteIcon,
  LocalOffer as OfferIcon,
  Accessible as AccessibleIcon,
} from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { routes as routesPaths } from "../../routes";
import * as authActions from "../../features/authentication/auth-slice";
import { useCurrentUser } from "../../features/authentication/use-current-user";

export const drawerWidth = 240;
const Hidden: any = HiddenMui;
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: theme.spacing(8),
    background: theme.palette.primary.main,
  },
  navbar: {
    color: theme.palette.common.white,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  icon: { color: theme.palette.common.white },
  lowerDivision: { backgroundColor: theme.palette.common.white },
  mainItems: { flex: 1 },
  secondaryItems: { flexBasis: theme.spacing(15) },
}));

const NavigationDrawer: React.FC<{
  onDrawerToggle: () => void;
  mobileOpen: boolean;
  window?: Window;
}> = ({ onDrawerToggle, mobileOpen, window: mobileWindow }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { user } = useCurrentUser();
  const userRoles = {
    admin: "admin",
    user: "user",
    uciMedic: "uciMedic",
    procurer: "procurer",
    coordinator: "coordinator",
  };

  const routes = [
    {
      icon: <HomeIcon />,
      label: "Inicio",
      to: routesPaths.HOME,
      roles: [
        userRoles.admin,
        userRoles.user,
        userRoles.uciMedic,
        userRoles.procurer,
        userRoles.coordinator,
      ],
    },
    {
      icon: <ErrorIcon />,
      label: "Sospechas",
      to: routesPaths.SUSPICIONS,
      roles: [
        userRoles.admin,
        userRoles.user,
        userRoles.uciMedic,
        userRoles.procurer,
        userRoles.coordinator,
      ],
    },
    {
      icon: <FavoriteIcon />,
      label: "Órganos",
      to: routesPaths.ORGANS,
      roles: [userRoles.admin, userRoles.coordinator],
    },
    {
      icon: <OfferIcon />,
      label: "Ofertas",
      to: routesPaths.OFFERS,
      disabled: false,
      roles: [userRoles.admin, userRoles.procurer, userRoles.coordinator],
    },
    {
      icon: <AccessibleIcon />,
      label: "Cirugías",
      to: routesPaths.SURGERIES,
      roles: [userRoles.admin, userRoles.procurer, userRoles.coordinator],
    },
    {
      icon: <GroupIcon />,
      label: "Usuarios",
      to: routesPaths.USERS,
      roles: [userRoles.admin],
    },
    {
      icon: <LocalHospitalIcon />,
      label: "Hospitales",
      to: routesPaths.HOSPITALS,
      roles: [userRoles.admin],
    },
  ];

  const container =
    mobileWindow !== undefined ? () => mobileWindow.document.body : undefined;

  const logout = () => {
    window.localStorage.clear();
    dispatch(authActions.logout());
  };

  const noop = () => {};
  const navigationContent = (
    <div className={classes.navbar}>
      <List className={classes.mainItems}>
        {routes.map((route) => (
          <ListItem
            button
            selected={route.to === pathname}
            component={Link}
            onClick={mobileOpen ? onDrawerToggle : noop}
            to={route.to}
            key={route.label}
            disabled={
              route?.disabled || !route.roles.includes(user?.role ?? "")
            }
          >
            <ListItemIcon className={classes.icon}>{route.icon} </ListItemIcon>
            <ListItemText primary={route.label} />
          </ListItem>
        ))}
      </List>
      <Divider className={classes.lowerDivision} />
      <List className={classes.secondaryItems}>
        <ListItem button onClick={logout}>
          <ListItemIcon className={classes.icon}>
            <ExitIcon />
          </ListItemIcon>
          <ListItemText primary="Salir" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="navegación">
      <Hidden smUp implementation="js">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={onDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {navigationContent}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="js">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {navigationContent}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default NavigationDrawer;
