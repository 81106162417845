import React, { useState } from 'react';
import {
  Typography,
  makeStyles,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { useCan } from '../../utils/can';
import { rules } from '../../utils/rbac-rules';
import { offeringStatus, organStatus } from '../../constants/status';

const useStyles = makeStyles((theme) => ({
  summary: { display: 'flex', width: '100%', alignItems: 'center' },
  details: { display: 'flex', flexDirection: 'column', flex: 1 },
  title: { marginRight: theme.spacing(2), flex: 1 },
  offering: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  offeringContent: {
    padding: theme.spacing(2, 1),
    color: theme.palette.text.secondary,
  },
  organStatus: {
    flex: 4,
    fontWeight: theme.typography.h6.fontWeight,
    color: ({ status }: { status: string }) => {
      switch (status) {
        case organStatus.assigned:
          return theme.palette.success.main;
        case organStatus.beingAllocated:
          return theme.palette.warning.main;
        default:
          return theme.palette.text.secondary;
      }
    },
  },
  offeringStatus: {
    fontWeight: theme.typography.h6.fontWeight,
    color: ({ status }: { status: string }) => {
      switch (status) {
        case offeringStatus.accepted:
          return theme.palette.success.main;
        case offeringStatus.pending:
          return theme.palette.warning.main;
        case offeringStatus.dismissed:
          return theme.palette.error.main;
        case offeringStatus.expired:
          return theme.palette.text.hint;
        default:
          return theme.palette.text.secondary;
      }
    },
  },
  createAllocationButton: {},
}));

interface OfferingStatusProps {
  status: string;
}

const OfferingStatus: React.FC<OfferingStatusProps> = ({ status }) => {
  const classes = useStyles({ status });

  return (
    <Typography variant="caption" className={classes.offeringStatus}>
      {status}
    </Typography>
  );
};

interface OrganStatusProps {
  status: string;
}

const OrganStatus: React.FC<OrganStatusProps> = ({ status }) => {
  const classes = useStyles({ status });

  return (
    <Typography variant="caption" color="textSecondary" className={classes.organStatus}>
      {status}
    </Typography>
  );
};

interface InProgressOrganItemProps {
  organ: any; // Replace with the actual type of the "organ" prop
}

const InProgressOrganItem: React.FC<InProgressOrganItemProps> = ({ organ }) => {
  const classes = useStyles({status: ""});
  const canAllocate = useCan({ perform: rules.organs.allocate });
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion elevation={3} expanded={expanded}>
      <AccordionSummary
        onClick={() => canAllocate && setExpanded(!expanded)}
        expandIcon={
          canAllocate ? (
            <ExpandMoreIcon
            />
          ) : null
        }
      >
        <Box className={classes.summary}>
          <Typography variant="h6" className={classes.title}>
            {organ.organType.name}
          </Typography>
          <OrganStatus status={organ.status} />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.details}>
          <Divider />
          {canAllocate &&
            organ.offerings.map(({ id, priority, status, medicalCenter }: any) => (
              <React.Fragment key={id}>
                <Box className={classes.offering}>
                  <Box className={classes.offeringContent}>
                    <Typography variant="body1">{medicalCenter.name}</Typography>
                    <OfferingStatus status={status} />
                  </Box>
                  <Typography variant="caption">{priority}° prioridad</Typography>
                </Box>
                <Divider />
              </React.Fragment>
            ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default InProgressOrganItem;
