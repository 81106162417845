import { gql, QueryResult, useQuery } from '@apollo/client';

export const SURGERY_QUERY = gql`
  query SurgeryDetailQuery($surgeryId: ID!) {
    getSurgery(id: $surgeryId) {
      id
      status
      createdAt
      crossClamp
      medicalCenter {
        id
        name
      }
      suspicion {
        id
        rut
      }
      organs {
        id
        status
        crossClamp
        startProcure
        endProcure
        destinationMedicalCenter {
          id
          name
        }
        organType {
          id
          name
          ischemiaTime
        }
      }
      assignedOrgans {
        id
        status
        crossClamp
        startProcure
        endProcure
        destinationMedicalCenter {
          id
          name
        }
        organType {
          id
          name
          ischemiaTime
        }
      }
      scheduledAt
      updatedAt
    }
  }
`;

interface SurgeryDetailData {
  getSurgery: Surgery | null;
}

interface Surgery {
  id: string;
  status: string;
  createdAt: string;
  crossClamp: string;
  medicalCenter: {
    id: string;
    name: string;
  };
  suspicion: {
    id: string;
    rut: string;
  };
  organs: Organ[];
  assignedOrgans: Organ[];
  scheduledAt: string;
  updatedAt: string;
}

interface Organ {
  id: string;
  status: string;
  crossClamp: string;
  startProcure:string;
  endProcure:string;
  destinationMedicalCenter: {
    id: string;
    name: string;
  };
  organType: {
    id: string;
    name: string;
    ischemiaTime: string;
  };
}

export function useSurgery(surgeryId: string): QueryResult<SurgeryDetailData> {
  return useQuery<SurgeryDetailData>(SURGERY_QUERY, {
    variables: { surgeryId },
  });
}
