import {
  Paper,
  Grid,
  Theme,
  createStyles,
  
} from "@material-ui/core";
import React from "react";
import PatientsList from "../features/organs/patients-list";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';

import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth: 500,
    },
  })
);

const OrgansPage: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent='flex-start' spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h6' component='div'>
            Órganos
          </Typography>
        </Grid>
            <PatientsList />
      </Grid>
    </div>
  );
};

export default OrgansPage;
