import { gql, useQuery } from '@apollo/client';

export const CURRENT_USER_QUERY = gql`
  query GetAuthenticatedUser {
    getAuthenticatedUser {
      id
      firstName
      lastName
      email
      role
      medicalCenters {
        id
      }
    }
  }
`;

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  medicalCenters: { id: string }[];
}

interface CurrentUserQueryResult {
  getAuthenticatedUser: User | null;
}

export function useCurrentUser() {
  const { data, loading, error, refetch } = useQuery<CurrentUserQueryResult>(CURRENT_USER_QUERY);

  const user = data?.getAuthenticatedUser;

  return { user, loading, error, refetch };
}