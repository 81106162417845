import React from "react";
import { Grid, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";
import { changeDateFormat } from "../../utils/date-format";
import ConfirmBrainDeathButton from "./confirm-brain-death-button";
import { useCurrentUser } from "../authentication/use-current-user";
import Can from "../../utils/can";
import { rules } from "../../utils/rbac-rules";
import SuspicionAccordionSummary from "../suspicions/suspicion-expansion-panel-summary";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  iconLabel: {
    fontSize: 14,
    marginLeft: theme.spacing(-7),
    marginTop: theme.spacing(1),
  },
  detailTitle: {
    paddingLeft: theme.spacing(2),
    fontSize: "16px",
    color: theme.palette.text.secondary,
  },
  detailData: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  detailDataTime: {
    paddingLeft: theme.spacing(2),
    color: "gray",
  },
  detailUser: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: "16px",
  },
  detailNotConfirmed: {
    paddingLeft: theme.spacing(24),
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    fontSize: "16px",
  },
  detailDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  download: {
    color: theme.palette.primary.main,
  },
  disableDownload: {
    color: "gray",
  },
  itemsDeath: {
    display: "flex",
    alignItems: "center",
  },
}));

interface BrainDeathDetailsProps {
  suspicion: {
    id: string;
    brainDeath: {
      createdAt: string;
      creator: {
        firstName: string;
        lastName: string;
      };
      document: string;
      comments: string;
    };
  };
  isExpanded: boolean;
  onClick: () => void;
}

const BrainDeathDetails: React.FC<BrainDeathDetailsProps> = ({
  suspicion,
  isExpanded,
  onClick,
}) => {
  const classes = useStyles();
  const { user } = useCurrentUser();

  return (
    <>
      <div className={classes.root}>
        <Accordion expanded={isExpanded}>
          <SuspicionAccordionSummary
            label="Muerte cerebral"
            onClick={onClick}
            isExpanded={isExpanded}
            suspicion={suspicion}
            stepName="brainDeath"
            testId="details-brain-death-btn"
          >
            <Can
              role={user!.role}
              perform={rules.brainDeath.create}
              yes={() =>
                (!suspicion.brainDeath && (
                  <ConfirmBrainDeathButton
                    suspicionId={suspicion.id}
                    data-testid="add-brain-death-confirmation-button"
                  >
                    Ingresar
                  </ConfirmBrainDeathButton>
                )) as any
              }
            />
          </SuspicionAccordionSummary>
          {suspicion.brainDeath ? (
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.detailDataTime}>
                    {changeDateFormat(new Date(suspicion.brainDeath.createdAt))}
                  </Typography>
                  <Typography className={classes.detailData}>
                    Ingresados y confirmados por:
                  </Typography>
                  <Typography className={classes.detailUser}>
                    {suspicion.brainDeath.creator.firstName}{" "}
                    {suspicion.brainDeath.creator.lastName}
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <div className={classes.itemsDeath}>
                    <Link target="_blank" href={suspicion.brainDeath.document}>
                      <Button disabled={!suspicion.brainDeath.document}>
                        <DescriptionIcon
                          className={
                            suspicion.brainDeath.document
                              ? classes.download
                              : classes.disableDownload
                          }
                        />
                      </Button>
                    </Link>

                    <Typography>Documento</Typography>
                  </div>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.detailTitle}>
                    Comentarios adicionales
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.detailData}>
                    {suspicion.brainDeath.comments === ""
                      ? "Sin comentarios"
                      : suspicion.brainDeath.comments}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          ) : null}
        </Accordion>
      </div>
    </>
  );
};

export default BrainDeathDetails;
