import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Yup from '../../utils/validations';


import ResponsiveDialog from '../../components/ui/responsive-dialog';
import { CONFIRMED_SUSPICIONS_QUERY } from './use-confirmed-suspicions';
import { useAllMedicalCenters } from '../medical-centers/use-all-medical-centers';
import FormSingleAutocomplete from '../../components/forms/form-single-autocomplete';
import { OFFERINGS_QUERY } from '../offerings/use-all-offerings';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(2, 0, 2),
    },
    buttonExpanded: {
      color: 'white',
    },
    button: {
      color: theme.palette.primary.main,
    },
  })
);

export const CREATE_ALLOCATION_MUTATION = gql`
  mutation CreateOfferingMutation($offeringInput: OfferingInput!) {
    createOffering(offeringInput: $offeringInput) {
      id
    }
  }
`;

const crossRequirementMessage = 'Este campo es requerido si tienes prioridades menores';

const validationSchema = Yup.object({
  medicalCenter1: Yup.string().required(),
  medicalCenter2: Yup.string().when('medicalCenter3', {
    is: (medicalCenter: any) => !!medicalCenter,
    then: Yup.string().required(crossRequirementMessage),
  }),
  medicalCenter3: Yup.string().when('medicalCenter4', {
    is: (medicalCenter: any) => !!medicalCenter,
    then: Yup.string().required(crossRequirementMessage),
  }),
  medicalCenter4: Yup.string().when('medicalCenter5', {
    is: (medicalCenter: any) => !!medicalCenter,
    then: Yup.string().required(crossRequirementMessage),
  }),
  medicalCenter5: Yup.string(),
});

interface CreateAllocationDialogProps {
  organ: any; // Replace with the actual type of the "organ" prop
  open: boolean;
  onClose: () => void;
}

const CreateAllocationDialog: React.FC<CreateAllocationDialogProps> = ({ organ, open, onClose }) => {
  const [allocateOrgan, { loading }] = useMutation(CREATE_ALLOCATION_MUTATION, {
    refetchQueries: [{ query: CONFIRMED_SUSPICIONS_QUERY }, { query: OFFERINGS_QUERY }],
  });
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { allMedicalCenters } = useAllMedicalCenters();

  const handleSubmit = async (values: any) => {
    try {
      const preMedicalCenterIds = [
        values.medicalCenter1,
        values.medicalCenter2,
        values.medicalCenter3,
        values.medicalCenter4,
        values.medicalCenter5,
      ];
      await allocateOrgan({
        variables: {
          offeringInput: {
            organId: organ.id,
            medicalCenterIds: preMedicalCenterIds.filter((id) => !!id),
          },
        },
      });
      enqueueSnackbar('Órgano ofrecido correctamente', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Hubo un error al ofrecer el órgano', {
        variant: 'error',
      });
    }
    onClose();
  };

  return (
    <>
      <ResponsiveDialog
        // align="center"
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Formik
          initialValues={{
            medicalCenter1: undefined,
            medicalCenter2: undefined,
            medicalCenter3: undefined,
            medicalCenter4: undefined,
            medicalCenter5: undefined,
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ submitForm, values }) => {
            const selectedIds = Object.values(values).filter(i => !!i)
            const validOptions = allMedicalCenters.filter((item: any) => {
              return !selectedIds.includes(item.id)
            })
            return (
              (
                <Container component="main" maxWidth="xs">
                  <div className={classes.paper}>
                    <DialogTitle id="responsive-dialog-title">
                      Crear oferta de {organ.organType.name}
                    </DialogTitle>
                    <Form className={classes.form}>
                      <Grid container>
                        <Grid item xs={12}>
                          <FormSingleAutocomplete
                            name="medicalCenter1"
                            label="Primera prioridad"
                            options={validOptions}
                            getOptionLabel={(option: any) => option?.name}
                            renderOption={(option: any) => option?.name}
                            getValueFromOption={(option: any) => option?.id}
                          />
                          <br />
                          <FormSingleAutocomplete
                            name="medicalCenter2"
                            label="Segunda prioridad"
                            options={validOptions}
                            getOptionLabel={(option: any) => option?.name}
                            renderOption={(option: any) => option?.name}
                            getValueFromOption={(option: any) => option?.id}
                          />
                          <br />
                          <FormSingleAutocomplete
                            name="medicalCenter3"
                            label="Tercera prioridad"
                            options={validOptions}
                            getOptionLabel={(option: any) => option?.name}
                            renderOption={(option: any) => option?.name}
                            getValueFromOption={(option: any) => option?.id}
                          />
                          <br />
                          <FormSingleAutocomplete
                            name="medicalCenter4"
                            label="Cuarta prioridad"
                            options={validOptions}
                            getOptionLabel={(option : any) => option?.name}
                            renderOption={(option: any) => option?.name}
                            getValueFromOption={(option: any) => option?.id}
                          />
                          <br />
                          <FormSingleAutocomplete
                            name="medicalCenter5"
                            label="Quinta prioridad"
                            options={validOptions}
                            getOptionLabel={(option: any) => option?.name}
                            renderOption={(option: any) => option?.name}
                            getValueFromOption={(option: any) => option?.id}
                          />
                        </Grid>
                      </Grid>
                    </Form>
                  </div>
                  <DialogActions>
                    <Button onClick={onClose} color="secondary">
                      Cancelar
                    </Button>
                    <Button
                      data-testid="enter-medical-record-button"
                      onClick={submitForm}
                      disabled={loading}
                      color="primary"
                      type="submit"
                      variant="contained"
                    >
                      Seleccionar
                    </Button>
                  </DialogActions>
                </Container>
              )
            )
          }}
        </Formik>
      </ResponsiveDialog>
    </>
  );
};

export default CreateAllocationDialog;
