import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logoIcon from '../../assets/logo.png';
import trasplanTypography from '../../assets/trasplan.png';

const useStyles = makeStyles(() => ({
icon: {
height: 130,
width: 135,
},
typography: {
height: 40,
width: 170,
},
}));

const VerticalTypographicLogoIconComponent: React.FC = () => {
const classes = useStyles();

return (
<>
<img src={logoIcon} alt="Logo Icon" className={classes.icon} />
<img
     src={trasplanTypography}
     alt="Trasplan Typography Icon"
     className={classes.typography}
   />
</>
);
};

export default VerticalTypographicLogoIconComponent;
