import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { ExitToApp as AddIcon } from '@material-ui/icons';
import useModal from '../../hooks/useModal';
import ConfirmFamilyActivationDialog from './create-family-activation-dialog';

interface ConfirmFamilyActivationButtonProps {
  suspicionId: string;
  children: React.ReactNode;
}

const ConfirmFamilyActivationButton: React.FC<ConfirmFamilyActivationButtonProps & ButtonProps> = ({
  suspicionId,
  children,
  ...buttonProps
}) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();

  return (
    <>
      <Button
        onClick={openModal}
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        {...buttonProps}
      >
        {children}
      </Button>
      <ConfirmFamilyActivationDialog
        suspicionId={suspicionId}
        open={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default ConfirmFamilyActivationButton;
