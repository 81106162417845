import React from 'react';
import { Button } from '@material-ui/core';
import { ExitToApp as AddIcon } from '@material-ui/icons';
import useModal from '../../hooks/useModal';
import CreateInclusionCriteriaDialog from './create-inclusion-criteria-dialog';

interface CreateInclusionCriteriaButtonProps {
  suspicion: any;
  [key: string]: any;
}

const CreateInclusionCriteriaButton: React.FC<CreateInclusionCriteriaButtonProps> = ({
  suspicion,
  ...buttonProps
}) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();

  return (
    <>
      <Button
        data-testid="add-inclusion-criteria-button"
        onClick={openModal}
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        {...buttonProps}
      >
        Ingresar
      </Button>
      <CreateInclusionCriteriaDialog
        suspicion={suspicion}
        open={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default CreateInclusionCriteriaButton;
