import { useEffect, useState } from 'react';
import { useCurrentUser } from '../features/authentication/use-current-user';
import { rulesUserRoles } from './rbac-rules';

const check = (rules: Record<string, string[]>, role: string, action: string): boolean => {
  const permissions = rules[role];
  if (!permissions) {
    return false;
  }

  return permissions.includes(action);
};

interface CanProps {
  role: string;
  perform: string;
  yes?: () => JSX.Element | null;
  no?: () => JSX.Element | null;
}

const Can: React.FC<CanProps> = ({ role, perform, yes, no }) =>
  check(rulesUserRoles, role, perform) ? (yes ? yes() : null) : (no ? no() : null);

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export function useCan({ perform }: { perform: string }): boolean {
  const { user } = useCurrentUser();
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    setIsAllowed(check(rulesUserRoles, user!.role, perform));
  }, [perform, user?.role]);

  return isAllowed;
}

export default Can;