import React from "react";
import { useMutation } from "@apollo/client";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { gql } from "apollo-boost";
import { Formik, Form } from "formik";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { validate } from "rut.js";
import FormSelectField from "../../components/forms/form-select-field";
import FormTextField from "../../components/forms/form-text-field";
import FormSingleAutocomplete from "../../components/forms/form-single-autocomplete";
import ResponsiveDialog from "../../components/ui/responsive-dialog";
import Yup from "../../utils/validations";
import { ALL_SUSPICIONS_QUERY } from "./use-all-suspicions";
import { useAllMedicalCenters } from "../medical-centers/use-all-medical-centers";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(1),
  },
}));

export const CREATE_SUSPICION_MUTATION = gql`
  mutation CreateSuspicion(
    $suspicionInput: SuspicionInput!
    $medicalCenterId: ID
  ) {
    createSuspicion(
      suspicionInput: $suspicionInput
      medicalCenterId: $medicalCenterId
    ) {
      id
      rut
      sex
      confirmed
      active
      description
      status
      updatedAt
      medicalCenter {
        id
      }
      organs {
        id
      }
    }
  }
`;

interface CreateSuspicionDialogProps {
  open: boolean;
  onClose: () => void;
}

const suspicionSchema = Yup.object({
  suspicionInput: Yup.object().shape({
    sex: Yup.string(),
    rut: Yup.string()
      .required()
      .test("rut-format", "Rut es inválido.", validate),
    description: Yup.string(),
  }),
});

const CreateSuspicionDialog: React.FC<CreateSuspicionDialogProps> = ({
  open,
  onClose,
}) => {
  const history = useHistory();
  const [createSuspicion, { loading }] = useMutation(CREATE_SUSPICION_MUTATION);

  const classes = useStyles();
  const { allMedicalCenters } = useAllMedicalCenters();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      await createSuspicion({
        variables: values,
      });
      resetForm();
      enqueueSnackbar("Sospecha creada exitosamente", { variant: "success" });
      onClose();
      history.push("/suspicions");
    } catch (error: any) {
      console.log(
        "🚀 ~ file: create-suspicion-dialog.tsx:99 ~ handleSubmit ~ error:",
        error
      );
      const [graphQLError] = error.graphQLErrors;
      if (
        graphQLError?.extensions?.exception?.name ===
        "SequelizeUniqueConstraintError"
      )
        enqueueSnackbar("Este RUT ya está asociado a una sospecha", {
          variant: "error",
        });
      else
        enqueueSnackbar("Hubo un error al procesar su solicitud.", {
          variant: "error",
        });
    }
  };

  return (
    <Formik
      initialValues={{
        suspicionInput: {
          sex: "Femenino",
          rut: "",
          description: "",
        },
        medicalCenterId: undefined,
      }}
      onSubmit={handleSubmit}
      validationSchema={suspicionSchema}
    >
      {({ submitForm }) => (
        <Form>
          <ResponsiveDialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Levantar nueva sospecha
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Completa el formulario con los datos de la sospecha
              </DialogContentText>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormTextField
                  label="Rut"
                  required
                  name="suspicionInput.rut"
                  style={{ flexBasis: "60%" }}
                />
                <FormSelectField
                  label="Sexo"
                  name="suspicionInput.sex"
                  style={{ flexBasis: "34%" }}
                >
                  <MenuItem value="Femenino" data-testid="Femenino">
                    Femenino
                  </MenuItem>
                  <MenuItem value="Masculino" data-testid="Masculino">
                    Masculino
                  </MenuItem>
                </FormSelectField>
              </div>
              <FormSingleAutocomplete
                name="medicalCenterId"
                label="Elige un centro médico"
                options={allMedicalCenters || []}
                getOptionLabel={(option: any) => option.name}
                renderOption={(option: any) => option.name}
                getValueFromOption={(option: any) => option.id}
              />
              <FormTextField
                fullWidth
                label="Descripción (opcional)"
                name="suspicionInput.description"
                className={classes.margin}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancelar
              </Button>
              <Button
                onClick={submitForm}
                disabled={loading}
                color="primary"
                type="submit"
              >
                Crear
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        </Form>
      )}
    </Formik>
  );
};

export default CreateSuspicionDialog;
