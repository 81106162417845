import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, makeStyles, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { ReportProblemOutlined as DangerIcon } from '@material-ui/icons';

import { useCan } from '../../utils/can';
import { rules } from '../../utils/rbac-rules';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { SURGERY_QUERY } from './use-surgery';
import { ALL_SURGERIES_QUERY } from './use-all-surgeries';
import { organStatus } from '../../constants/status';

const useStyles = makeStyles((theme) => ({
  dismissOrganButton: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.getContrastText(theme.palette.error.dark),
    },
  },
}));

const DISMISS_ORGAN_MUTATION = gql`
  mutation DismissOrgan($organId: ID!) {
    updateOrganStatus(id: $organId, status: "Desestimado") {
      id
    }
  }
`;

interface DismissOrganButtonProps {
  surgery: { id: string; suspicion: { rut: string } };
  organ: { id: string; status: string; organType: { name: string } };
}

const DismissOrganButton: React.FC<DismissOrganButtonProps> = ({ surgery, organ }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const canRejectOrgan = useCan({ perform: rules.surgery.reject });
  const [dismissOrgan, { loading }] = useMutation(DISMISS_ORGAN_MUTATION, {
    refetchQueries: [
      { query: SURGERY_QUERY, variables: { surgeryId: surgery.id } },
      { query: ALL_SURGERIES_QUERY },
    ],
  });

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSubmit = async () => {
    closeModal();

    try {
      await dismissOrgan({ variables: { organId: organ.id } });
      enqueueSnackbar('Órgano desestimado exitosamente', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Hubo un error al desestimar el órgano', {
        variant: 'error',
      });
    }
  };

  if (!canRejectOrgan || organ?.status === organStatus.rejected) return null;

  return (
    <>
      <Button
        className={classes.dismissOrganButton}
        onClick={() => openModal()}
        variant="contained"
        size="small"
        data-testid="reject-organ-button"
        startIcon={<DangerIcon />}
      >
        Desestimar
      </Button>
      <ResponsiveDialog
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="reject-organ-dialog"
      >
        <DialogTitle id="reject-organ-dialog">
          Desestimar {organ.organType.name.toLowerCase()} de {surgery.suspicion.rut}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer desestimar este órgano?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Volver
          </Button>
          <Button onClick={handleSubmit} disabled={loading} color="primary" type="submit">
            Desestimar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default DismissOrganButton;
