import { Button } from '@material-ui/core';
import React from 'react';
import useModal from '../../hooks/useModal';
import CreateAllocationDialog from './create-allocation-dialog';

interface CreateAllocationButtonProps {
  organ: any; // Replace with the actual type of the "organ" prop
}

const CreateAllocationButton: React.FC<CreateAllocationButtonProps> = ({ organ }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  return (
    <>
      <Button
        size="small"
        color="secondary"
        variant="contained"
        onClick={openModal}
        id="create-allocation-button"
      >
        Alocar
      </Button>
      <CreateAllocationDialog organ={organ} open={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default CreateAllocationButton;

