import React from "react";
import { Grid, Typography, Box, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import Divider from "@material-ui/core/Divider";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { changeDateFormat } from "../../utils/date-format";
import CreateInclusionCriteriaButton from "./create-inclusion-criteria-button";
import ConfirmInclusionCriteriaButton from "./confirm-inclusion-criteria-dialog";
import { useCurrentUser } from "../authentication/use-current-user";
import Can from "../../utils/can";
import { rules } from "../../utils/rbac-rules";
import SuspicionAccordionSummary from "../suspicions/suspicion-expansion-panel-summary";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  expanded: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  notExpanded: {
    backgroundColor: theme.palette.secondary.main,
  },
  buttonExpanded: {
    color: "white",
  },
  checkboxLabel: {
    fontSize: 14,

    width: "20%", // Ocupar todo el ancho en pantallas pequeñas
    [theme.breakpoints.up("lg")]: {
      width: "auto", // Restaurar el ancho por defecto en pantallas más grandes
    },
  },
  detailTitle: {
    paddingLeft: theme.spacing(2),
    fontSize: "16px",
    color: theme.palette.text.secondary,
  },
  detailData: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  detailDataTime: {
    paddingLeft: theme.spacing(2),
    color: "gray",
  },
  detailUser: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: "16px",
  },
  detailNotConfirmed: {
    textAlign: "right",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    fontSize: "16px",
  },
  detailDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  detailCheckbox: {
    marginTop: theme.spacing(0.5),
  },
  download: {
    color: theme.palette.primary.main,
  },
  disableDownload: {
    color: "gray",
  },
  heading: {
    fontSize: "16px",
  },
  itemsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    flexDirection: "column", // Cambio de dirección para pantallas pequeñas
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row", // Restaurar dirección para pantallas más grandes
    },
  },
  items: {
    marginTop: "4px",
  },
  checkboxBox: {
    marginTop: "4px",
    display: "flex",
  },
  textCheckbox: {
    marginLeft: "3px",
  },
}));

interface InclusionCriteriaDetailsProps {
  suspicion: any;
  isExpanded: boolean;
  onClick: () => void;
}

const InclusionCriteriaDetails: React.FC<InclusionCriteriaDetailsProps> = ({
  suspicion,
  isExpanded,
  onClick,
}) => {
  const classes = useStyles();
  const { user } = useCurrentUser();

  return (
    <>
      <div className={classes.root}>
        <Accordion expanded={isExpanded}>
          <SuspicionAccordionSummary
            label="Criterios de inclusión"
            onClick={onClick}
            isExpanded={isExpanded}
            suspicion={suspicion}
            stepName="inclusionCriteria"
            testId="details-inclusion-criteria-btn"
          >
            {!suspicion?.inclusionCriteria && (
              <Can
                role={user!.role}
                perform={rules?.inclusionCriteria?.create}
                yes={() =>
                  suspicion?.inclusionCriteria ? null : (
                    <CreateInclusionCriteriaButton suspicion={suspicion} />
                  )
                }
              />
            )}
          </SuspicionAccordionSummary>
          {suspicion?.inclusionCriteria && (
            <AccordionDetails>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.detailDataTime}>
                    {changeDateFormat(suspicion.inclusionCriteria.createdAt)}
                  </Typography>
                  <Typography className={classes.detailData}>
                    Criterios de Inclusión ingresados por:{" "}
                  </Typography>
                  <Typography className={classes.detailUser}>
                    {suspicion.inclusionCriteria.creator.firstName}{" "}
                    {suspicion.inclusionCriteria.creator.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {suspicion.inclusionCriteria.confirmation?.status ? (
                    <>
                      <Typography className={classes.detailDataTime}>
                        {changeDateFormat(
                          suspicion.inclusionCriteria.confirmation.updatedAt
                        )}
                      </Typography>
                      <Typography className={classes.detailData}>
                        Confirmados por:{" "}
                      </Typography>
                      <Typography className={classes.detailUser}>
                        {
                          suspicion.inclusionCriteria.confirmation.user
                            .firstName
                        }{" "}
                        {suspicion.inclusionCriteria.confirmation.user.lastName}
                      </Typography>
                    </>
                  ) : (
                    <Typography className={classes.detailNotConfirmed}>
                      Por confirmar
                    </Typography>
                  )}
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <div className={classes.itemsBox}>
                  <div className={classes.items}>
                    <Link
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      href={suspicion.inclusionCriteria.vih}
                    >
                      <Button disabled={!suspicion.inclusionCriteria.vih}>
                        <DescriptionIcon
                          className={
                            suspicion.inclusionCriteria.vih
                              ? classes.download
                              : classes.disableDownload
                          }
                        />
                        <Typography>VIH</Typography>
                      </Button>
                    </Link>
                  </div>
                  <div className={classes.items}>
                    <Link
                      style={{ textDecoration: "none" }}
                      href={suspicion.inclusionCriteria.vhb}
                    >
                      <Button disabled={!suspicion.inclusionCriteria.vhb}>
                        <DescriptionIcon
                          className={
                            suspicion.inclusionCriteria.vhb
                              ? classes.download
                              : classes.disableDownload
                          }
                        />
                        <Typography>VHB</Typography>
                      </Button>
                    </Link>
                  </div>
                  <div className={classes.items}>
                    <Link
                      style={{ textDecoration: "none" }}
                      href={suspicion.inclusionCriteria.vhc}
                    >
                      <Button disabled={!suspicion.inclusionCriteria.vhc}>
                        <DescriptionIcon
                          className={
                            suspicion.inclusionCriteria.vhc
                              ? classes.download
                              : classes.disableDownload
                          }
                        />
                        <Typography>VHC</Typography>
                      </Button>
                    </Link>
                  </div>

                  <div className={classes.checkboxBox}>
                    {suspicion.inclusionCriteria.hasCancer ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                    <Typography className={classes.textCheckbox}>
                      Tiene Cáncer
                    </Typography>
                  </div>
                  <div>
                    <div className={classes.checkboxBox}>
                      {suspicion.inclusionCriteria.hasSepsis ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                      <Typography className={classes.textCheckbox}>
                        Tiene Sepsis
                      </Typography>
                    </div>
                  </div>
                </div>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.detailTitle}>
                    Comentarios adicionales
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.detailData}>
                    {suspicion.inclusionCriteria.comments === ""
                      ? "Sin comentarios"
                      : suspicion.inclusionCriteria.comments}
                  </Typography>
                </Grid>
                <Grid container justifyContent="flex-end">
                  <Box>
                    <Can
                      role={user!.role}
                      perform={rules.inclusionCriteria.confirm}
                      yes={() =>
                        suspicion.inclusionCriteria &&
                        !suspicion.inclusionCriteria.confirmation?.status && (
                          <ConfirmInclusionCriteriaButton
                            inclusionCriteria={suspicion.inclusionCriteria}
                            suspicion={suspicion}
                          />
                        )
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          )}
        </Accordion>
      </div>
    </>
  );
};

export default InclusionCriteriaDetails;
