import React from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import ResponsiveDialog from "../../components/ui/responsive-dialog";
import useModal from "../../hooks/useModal";
import { SUSPICION_QUERY } from "../suspicions/use-suspicion";

export const CONFIRM_MEDICAL_RECORD_MUTATION = gql`
  mutation ConfirmMedicalRecordMutation($medicalRecordId: ID!) {
    confirmMedicalRecord(id: $medicalRecordId) {
      id
      confirmation {
        id
        status
        updatedAt
      }
      comments
      updatedAt
      files
    }
  }
`;

interface ConfirmMedicalRecordButtonProps {
  children: React.ReactNode;
  medicalRecord: { id: string };
  suspicion: { id: string };
}

const ConfirmMedicalRecordButton: React.FC<ConfirmMedicalRecordButtonProps> = ({
  children,
  medicalRecord,
  suspicion,
  ...buttonProps
}) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const [confirmMedicalRecord, { loading }] = useMutation(
    CONFIRM_MEDICAL_RECORD_MUTATION,
    {
      refetchQueries: [
        {
          query: SUSPICION_QUERY,
          variables: {
            suspicionId: suspicion.id,
          },
        },
      ],
    }
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      await confirmMedicalRecord({
        variables: { medicalRecordId: medicalRecord.id },
      });
      enqueueSnackbar("Fichas médicas confirmadas exitosamente", {
        variant: "success",
      });
      closeModal();
    } catch (error) {
      enqueueSnackbar(
        "Ha ocurrido un error, el resumen clínico no se pudo confirmar.",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={openModal}
        data-testid="confirm-medical-record-button"
        {...buttonProps}
      >
        {children}
      </Button>
      <ResponsiveDialog
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirmar resumen clínico / laboratorio
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer confirmar las resumen clínico / laboratorio
            de esta sospecha?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} disabled={loading} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default ConfirmMedicalRecordButton;
