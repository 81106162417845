import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    padding: theme.spacing(3),
  },
}));

const SurgeryMap: React.FC = () => {
  const classes = useStyles();

  // TODO: cambiar a mapa
  return (
    <>
      <Box className={classes.main}>
        <img
          src="https://s3-alpha-sig.figma.com/img/fd79/d847/0fe45de08c6bdff761c90f0ad932719d?Expires=1594598400&Signature=YCLbqPAKGHZ-1WWpF7Xvx2UsNwW7j0VPywyJrHPteIHZHGIYcHdtCIei9F-L~JH4p8Yp~01Z3rOgDbhxo~7XO7yLKLaGhdUKB5qvflhq4WSpMg49hqFsNX46v4rgXdK5ATb0vZS7JWl1ZPlnJ53EiQofyd7ERNJoji9UjvQ1ihiuY9AJMaDfgazq1MC2FGaF0zUjwmGyDVxT0GVrHojVWoQAAWdDMlDDR8EM~h8kjmvnn3jd~ayzq40ubMiPPKB-oymyBn4Iy3qJS5NrOnnS7S1G9qe8pav~U6RMwlZECLeMtcsFBlFOe9fKILf0Wy8VVO2SRA0ELzSDkIexNb6kPQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
          alt="Google Maps"
          width="100%"
        />
      </Box>
    </>
  );
};

export default SurgeryMap;
