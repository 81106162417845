import React from 'react';
import {
  Stepper,
  Step,
  Box,
  StepConnector,
  makeStyles,
  withStyles,
  StepLabel,
} from '@material-ui/core';
import clsx from 'clsx';

const CustomStepLabel = withStyles({
  label: { fontSize: 11, whiteSpace: 'nowrap' },
  labelContainer: { width: 58 },
})(StepLabel);

const useStyles = makeStyles((theme: any) => ({
  stepper: {
    marginLeft: theme.spacing(-3.5),
    padding: theme.spacing(1.5),
    backgroundColor: 'transparent',
    opacity: 0.8,
  },
  root: { color: theme.palette.grey[400] },
  active: {
    color: ({ color }: { color: string }) =>
      color ? theme.palette[color].main : theme.palette.primary.main,
  },
  circle: { width: 10, height: 10, borderRadius: '50%', backgroundColor: 'currentColor' },
  completed: {
    backgroundColor: ({ color }: { color: string }) =>
      color ? theme.palette[color].main : theme.palette.primary.main,
  },
}));

interface CircleStepIconProps {
  active: boolean;
  completed: boolean;
  color: string;
}

const CircleStepIcon: React.FC<CircleStepIconProps> = ({ active, completed, color }) => {
  const classes = useStyles({ color });
  return (
    <Box className={clsx(classes.root, { [classes.active]: active })}>
      <Box className={clsx(classes.circle, { [classes.completed]: completed })} />
    </Box>
  );
};

interface CustomStepperProps {
  steps: string[];
  activeStep: string;
  color?: string;
}

const CustomStepper: React.FC<CustomStepperProps> = ({ steps, activeStep, color = 'primary', ...props }) => {
  const classes = useStyles({color:''});

  const connectorClasses = makeStyles((theme: any) => ({
    active: { '& $line': { borderColor: theme.palette[color].main } },
    completed: { '& $line': { borderColor: theme.palette[color].main } },
    alternativeLabel: { top: 3, left: 'calc(-50%)', right: 'calc(50%)' },
    root: { zIndex: -1 },
    line: { borderColor: theme.palette.grey[400], borderTopWidth: 5 },
  }))();

  return (
    <Stepper
      className={classes.stepper}
      activeStep={steps.indexOf(activeStep)}
      connector={<StepConnector classes={connectorClasses} />}
      alternativeLabel
      {...props}
    >
      {steps.map((label) => (
        <Step key={label}>
          <CustomStepLabel StepIconComponent={CircleStepIcon} StepIconProps={{ color }}>
            {label}
          </CustomStepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
