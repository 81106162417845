import { gql, QueryResult, useQuery } from '@apollo/client';

interface OrganType {
  id: string;
  name: string;
  description: string;
  ischemiaTime: number;
  createdAt: string;
  updatedAt: string;
}

interface AllOrganTypesData {
  getOrganTypes: OrganType[];
}

const ALL_ORGAN_TYPES_QUERY = gql`
  query AllOrganTypes {
    getOrganTypes {
      id
      name
      description
      ischemiaTime
      createdAt
      updatedAt
    }
  }
`;

export function useAllOrganTypes(): QueryResult<AllOrganTypesData> {
  return useQuery<AllOrganTypesData>(ALL_ORGAN_TYPES_QUERY);
}
