import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Box,
} from "@material-ui/core";
import { Form, Formik, FormikValues, ErrorMessage } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import ResponsiveDialog from "../../components/ui/responsive-dialog";
import useModal from "../../hooks/useModal";
import Yup from "../../utils/validations";

import { useAllOrganTypes } from "../organs/useAllOrganTypes";
import { useConfirmSuspicion } from "./use-confirm-suspicion";

const schema = Yup.object().shape({
  organTypeIds: Yup.array()
    .of(Yup.string())
    .required("Debes seleccionar al menos un órgano")
    .min(1),
});

const ConfirmSuspicionButton = ({
  suspicion,
  children,
}: {
  suspicion: any;
  children: React.ReactNode;
}) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { loading: organTypesLoading, ...data }: any = useAllOrganTypes();
  console.log(
    "🚀 ~ file: confirm-suspicion-button.tsx:33 ~ ConfirmSuspicionButton ~ allOrganTypes:",
    data
  );
  const allOrganTypes = data?.data?.getOrganTypes;
  const [confirmSuspicion, { loading }] = useConfirmSuspicion({
    suspicionId: suspicion.id,
  });

  const handleSubmit = async (values: FormikValues) => {
    closeModal();
    try {
      await confirmSuspicion({
        variables: { suspicionId: suspicion.id, ...values },
      });
      enqueueSnackbar("Sospecha confirmada exitosamente", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Hubo un error al confirmar la sospecha", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={openModal}
        id="confirm-suspicion-btn"
        disabled={suspicion?.confirmation?.status}
      >
        {children}
      </Button>
      <Formik
        initialValues={{
          organTypeIds: [],
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, values, errors, setFieldValue, dirty }: any) => (
          <Form>
            <ResponsiveDialog
              // align="center"
              open={isModalOpen}
              onClose={closeModal}
              aria-labelledby="confirm-suspicion-dialog"
            >
              <DialogTitle id="confirm-suspicion-dialog">
                Confirmar a paciente {suspicion.rut} como donante
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Seleccione los potenciales órganos para donación.
                </DialogContentText>
                <ErrorMessage name="organTypeIds" component={FormHelperText} />
                <Box display="flex" flexDirection="column">
                  {!organTypesLoading &&
                    allOrganTypes?.map((organType: any) => (
                      <FormControlLabel
                        key={organType.id}
                        control={
                          <Checkbox
                            checked={values.organTypeIds.includes(organType.id)}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setFieldValue("organTypeIds", [
                                  ...values.organTypeIds,
                                  organType.id,
                                ]);
                              } else {
                                setFieldValue(
                                  "organTypeIds",
                                  values.organTypeIds.filter(
                                    (id: string) => id !== organType.id
                                  )
                                );
                              }
                            }}
                            name={organType.name}
                          />
                        }
                        label={organType.description}
                      />
                    ))}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeModal} color="secondary">
                  Volver
                </Button>
                <Button
                  onClick={submitForm}
                  disabled={!dirty || loading}
                  color="primary"
                  type="submit"
                >
                  Confirmar
                </Button>
              </DialogActions>
            </ResponsiveDialog>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ConfirmSuspicionButton;
