import { Button } from '@material-ui/core';
import React from 'react';
import useModal from '../../hooks/useModal';
import CreateMedicalRecordDialog from './create-medical-record-dialog';

interface ModifyMedicalRecordButtonProps {
  suspicion: any; // Replace 'any' with the appropriate type for 'suspicion'
}

const ModifyMedicalRecordButton: React.FC<ModifyMedicalRecordButtonProps> = ({ suspicion }) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();

  return (
    <>
      <Button
        data-testid="edit-medical-record-button"
        color="secondary"
        onClick={openModal}
      >
        Modificar
      </Button>
      <CreateMedicalRecordDialog
        suspicion={suspicion}
        open={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default ModifyMedicalRecordButton;
