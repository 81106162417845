import React from "react";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";

import CustomStepper from "../../components/ui/custom-stepper";
import { changeDateFormat } from "../../utils/date-format";
import { surgeryStatus } from "../../constants/status";
import ScheduleCrossClampButton from "./schedule-cross-clamp-button";
import { gql, useMutation } from "@apollo/client";
import { SURGERY_QUERY } from "./use-surgery";
import { ALL_SURGERIES_QUERY } from "./use-all-surgeries";

interface SurgeryBarProps {
  surgery: any;
}

const UPDATE_ORGAN_STATUS = gql`
  mutation UpdateOrganStatus($organId: ID!,$status: String) {
    updateOrganStatus(id: $organId, status: $status) {
      id
    }
  }
`;

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.primary.main,
      fontWeight: theme.typography.h6.fontWeight,
      textTransform: theme.typography.overline.textTransform,
    },
    body: { fontWeight: theme.typography.body1.fontWeight },
  })
)(TableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      lineHeight: "24px",
      letterSpacing: "0.15px",
    },
    header: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      lineHeight: "20px",
      letterSpacing: "0.25px",
    },
    inactive: { color: theme.palette.text.disabled },
  })
);

const SurgeryBar: React.FC<SurgeryBarProps> = ({ surgery }) => {

  const [UpdateOrganStatus, { loading }] = useMutation(UPDATE_ORGAN_STATUS, {
    refetchQueries: [
      { query: SURGERY_QUERY, variables: { surgeryId: surgery.id } },
      { query: ALL_SURGERIES_QUERY },
    ],
  });

  const updateOrgans = async (status: string, organId: string) => {
    try {
      await UpdateOrganStatus({ variables: { organId: organId, status } });
    } catch (error) {

    }
  }

  const classes = useStyles();



  const onProcura = async () => {
    if (surgery.organs && surgery.organs.length > 0) {
      for (const organ of surgery.organs) {
        await updateOrgans("InicioProcura", organ.id);
      }
    }
  };

  const onCrossClamp = async () => {
    if (surgery.organs && surgery.organs.length > 0) {
      for (const organ of surgery.organs) {
        await updateOrgans("CrossClamp", organ.id);
      }
    }
  };




  return (
    <Paper elevation={3} className={classes.paper}>
      <Table>
        <TableHead className={classes.header}>
          <TableRow>
            <StyledTableCell style={{ width: "20%" }}>Paciente</StyledTableCell>
            <StyledTableCell style={{ width: "20%" }}>
              Fecha de cross-clamp
            </StyledTableCell>
            <StyledTableCell style={{ width: "40%" }}>Estado</StyledTableCell>
            <StyledTableCell style={{ width: "20%" }}>
              Centro médico de origen
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell>{surgery.suspicion.rut}</StyledTableCell>
            <StyledTableCell>
              {surgery.crossClamp ? (
                changeDateFormat(surgery.crossClamp)
              ) : (
                <ScheduleCrossClampButton surgery={surgery} onProcura={onProcura} onCrossClamp={onCrossClamp} />
              )}
            </StyledTableCell>
            <StyledTableCell>
              <CustomStepper
                steps={Object.values(surgeryStatus)}
                activeStep={surgery.status}
              />
            </StyledTableCell>
            <StyledTableCell>{surgery.medicalCenter.name}</StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default SurgeryBar;
