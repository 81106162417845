import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

import FullPageSpinner from '../../components/ui/full-page-spinner';
import { useConfirmedSuspicions } from './use-confirmed-suspicions';
import PatientItem, { Suspicion } from './patient-item';


const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
}));

const PatientsList: React.FC = () => {
  const { loading, ...data }: any = useConfirmedSuspicions();
  const suspicions = data?.data?.getConfirmedSuspicions;
  const [expanded, setExpanded] = useState<string | null>(null);
  const classes = useStyles();

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <div style={{ width: '100%' }}  className={classes.root}>
      {suspicions?.map((suspicion: Suspicion) => (
        <PatientItem
          key={suspicion.id}
          suspicion={suspicion}
          isExpanded={expanded === suspicion.id}
          expand={() => setExpanded(expanded === suspicion.id ? null : suspicion.id)}
        />
      ))}
    </div>
  );
};

export default PatientsList;
