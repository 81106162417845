import React from 'react';
import { Paper, Typography, Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { changeDateFormat } from '../../utils/date-format';
import { useCan } from '../../utils/can';
import { rules } from '../../utils/rbac-rules';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2), display: 'flex', flexDirection: 'column' },
  subtitle: { color: theme.palette.text.hint },
  date: { color: theme.palette.text.hint },
  dismissed: { color: theme.palette.error.main },
  accepted: { color: theme.palette.success.main },
}));

interface ExpiredOfferingItemProps {
  offering: any; // Replace 'any' with the appropriate type for 'offering'
}

const localStatus = {
  accepted: 'Aceptada',
  rejected: 'Rechazada',
  expired: 'Expirada',
};

const ExpiredOfferingItem: React.FC<ExpiredOfferingItemProps> = ({ offering }) => {
  const classes = useStyles();
  const canManage = useCan({ perform: rules.offerings.manage });

  let status = '';
  if (offering.accepted) status = localStatus.accepted;
  else if (offering.rejected) status = localStatus.rejected;
  else if (offering.expired) status = localStatus.expired;

  return (
    <Paper elevation={1} className={classes.root}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">{offering.organ.organType.name}</Typography>
        <Typography variant="caption" className={classes.date}>
          {changeDateFormat(offering.createdAt)}
        </Typography>
      </Box>
      {canManage && (
        <Typography variant="overline">Para {offering.medicalCenter.name}</Typography>
      )}
      <Typography variant="caption" className={classes.subtitle}>
        Tu paciente fue {offering.priority}° prioridad
      </Typography>
      <Typography variant="caption" className={classes.subtitle}>
        Rut: {offering.organ.suspicion.rut}
      </Typography>
      <Typography
        variant="overline"
        className={clsx({
          [classes.accepted]: status === localStatus.accepted,
          [classes.dismissed]: status === localStatus.rejected,
        })}
      >
        {status}
      </Typography>
    </Paper>
  );
};

export default ExpiredOfferingItem;
