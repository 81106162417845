import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import useModal from '../../hooks/useModal';
import ScheduleSurgeryDialog from './schedule-surgery-dialog';
import { useCan } from '../../utils/can';
import { rules } from '../../utils/rbac-rules';

interface ScheduleSurgeryButtonProps {
  suspicion: any; // Adjust the type of suspicion accordingly
}

const useStyles = makeStyles((theme) => ({
  scheduleSurgeryButton: {
    marginRight: theme.spacing(1),
  },
}));

const ScheduleSurgeryButton: React.FC<ScheduleSurgeryButtonProps> = ({ suspicion }) => {
  const canSchedule = useCan({ perform: rules.surgery.schedule });
  const classes = useStyles();
  const { isModalOpen, open: openModal, close: closeModal } = useModal();

  if (!canSchedule) return null;
  return (
    <>
      <Button
        className={classes.scheduleSurgeryButton}
        onClick={(event) => {
          event.stopPropagation();
          openModal();
        }}
        variant="contained"
        color="primary"
        size="small"
        data-testid="schedule-surgery-button"
      >
        Agendar cirugía
      </Button>
      <ScheduleSurgeryDialog
        suspicion={suspicion}
        open={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default ScheduleSurgeryButton;
