import React, { useEffect } from 'react';
import { Paper, Typography, makeStyles, Grid, Box, Divider, Button } from '@material-ui/core';
import { addHours } from 'date-fns';
import clsx from 'clsx';

import { formatCountdown } from '../../utils/date-format';
import DismissOrganButton from './dismiss-organ-button';
import ConfirmTransplantButton from './confirm-transplant-button';
import CustomStepper from '../../components/ui/custom-stepper';
import { organStatus, surgeryStatus } from '../../constants/status';
import useCountdown from '../../hooks/useCountdown';
import ViewRouteButton from './view-route-button';
import ScheduleCrossClampButton from './schedule-cross-clamp-button';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/client';
import { SURGERY_QUERY } from './use-surgery';
import { ALL_SURGERIES_QUERY } from './use-all-surgeries';


const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2) },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
  },
  header: { display: 'flex', justifyContent: 'space-between' },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
  },
  infoItemTitle: {
    flex: 1,
    fontWeight: theme.typography.h6.fontWeight,
    color: theme.palette.text.hint,
  },
  timeLeft: { fontSize: theme.typography.h6.fontSize },
}));

const UPDATE_ORGAN_STATUS = gql`
  mutation UpdateOrganStatus($organId: ID!,$status: String) {
    updateOrganStatus(id: $organId, status: $status) {
      id
    }
  }
`;


interface OrganItemProps {
  surgery: { status: string, id: string };
  organ: {
    id: any;
    startProcure: any;
    endProcure: any;
    organType: { name: string; ischemiaTime: number };
    crossClamp: string;
    status: string;
    destinationMedicalCenter: { name: string };
  };
}

const OrganItem: React.FC<OrganItemProps> = ({ surgery, organ }) => {
  const classes = useStyles();
  const { timeLeft, refreshDate } = useCountdown({
    targetDate: addHours(new Date(organ.startProcure), organ.organType.ischemiaTime),
  });
  const [UpdateOrganStatus, { loading }] = useMutation(UPDATE_ORGAN_STATUS, {
    refetchQueries: [
      { query: SURGERY_QUERY, variables: { surgeryId: surgery.id } },
      { query: ALL_SURGERIES_QUERY },
    ],
  });

  useEffect(() => {
    if (organ.startProcure)
      refreshDate(addHours(new Date(organ.startProcure), organ.organType.ischemiaTime));
  }, [organ.startProcure, organ.organType.ischemiaTime, refreshDate]);

  const isInProgress =
    organ.status !== organStatus.transplanted && organ.status !== organStatus.rejected;


    const updateOrgans = async (status:string) => {
      try { 
        await UpdateOrganStatus({ variables: { organId: organ.id, status} });
      } catch (error) {
       
      }
    }
      
    
  return (
    <Paper elevation={3} className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box className={classes.header}>
            <Typography className={classes.title} color="primary">
              {organ.organType.name}
            </Typography>
            {organ.startProcure && isInProgress && (
              <Typography className={classes.timeLeft} color="primary">
                {formatCountdown(timeLeft, 'HH:mm:ss')}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
              Centro médico de destino
            </Typography>
            <Typography variant="body1">{organ.destinationMedicalCenter.name}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.infoItem}>
            <Typography variant="subtitle1" className={classes.infoItemTitle}>
              Tiempo total de isquemia
            </Typography>
            <Typography variant="body1">{organ.organType.ischemiaTime} horas</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.infoItemTitle}>
            Estado
          </Typography>
         
          <CustomStepper
            color={clsx({
              primary: isInProgress,
              error: organ.status === organStatus.rejected,
              success: organ.status === organStatus.transplanted,
            })}
            steps={[
              organStatus.assigned,
              organStatus.startProcure,
              organStatus.crossClamp,
              surgeryStatus.onIschemia,
              organStatus.endProcure,
              organ.status === organStatus.rejected
                ? organStatus.rejected
                : organStatus.transplanted,
            ]}
            activeStep={
              surgery.status === surgeryStatus.onIschemia &&
                organ.status === organStatus.crossClamp
                ? surgeryStatus.onIschemia
                : organ.status
            }


          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {organ.endProcure && isInProgress ? (
          <Grid item xs={12} className={classes.buttons}>
            <DismissOrganButton surgery={surgery as any} organ={organ as any} />
            <ConfirmTransplantButton surgery={surgery as any} organ={organ as any} />
          </Grid>
        ) : null}
        {organ.startProcure && isInProgress ? (
          <Grid item xs={12} className={classes.buttons}>
            <ViewRouteButton organ={organ} />
          </Grid>
        ) : null}
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={12} className={classes.buttons}>
          {/* {!organ.startProcure && (
            <Button
              onClick={(event) => {
                event.stopPropagation();
                console.log('Inicio de Procura', organ.status)
                updateOrgans ('InicioProcura')
              }}
              variant="contained"
              color="primary"
              size="small"
              data-testid="schedule-cross-clamp-button"

            >

              Inicio de Procura

            </Button>
          )} */}

          <>
            {/* {organ.startProcure && !organ.crossClamp && (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  console.log('Cross Clamp - Estado:', organ.status);
                  updateOrgans ('CrossClamp')
                }}
                variant="contained"
                color="primary"
                size="small"
                data-testid="cross-clamp-button"
              >
                Cross Clamp
              </Button>
            )} */}
            {organ.startProcure && organ.crossClamp && !organ.endProcure && (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  console.log('Fin de Procura - Estado:', organ.status);
                  updateOrgans ('FinalProcura')
                }}
                variant="contained"
                color="primary"
                size="small"
                data-testid="end-procure-button"
              >
                Fin de Procura
              </Button>

            )}
          </>

        </Grid>
      </Grid>
    </Paper>

  );
};

export default OrganItem;
