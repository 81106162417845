import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination as TP,
  TableRow,
} from "@material-ui/core";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  ExpandMore,
  ExpandLess,
} from "@material-ui/icons";
import {
  useSortBy,
  useFilters,
  usePagination,
  useTable,
  Column,
  Row,
} from "react-table";
import clsx from "clsx";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const TablePagination: any = TP;

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    flexShrink: 0,
  },
  headers: {
    backgroundColor: "lightgrey",
  },
  textHeader: {
    height: 0,
    width: 200,
  },
  textHeaderNotFilter: {
    color: theme.palette.primary.main,
    opacity: 1,
    fontWeight: 500,
    lineHeight: "28px",
    textTransform: "uppercase",
    width: 200,
  },
  noSortFilter: {
    width: 100,
    color: theme.palette.primary.main,
    opacity: 1,
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: "28px",
  },
  headerIcon: {
    paddingTop: theme.spacing(1),
    height: 25,
  },
  rows: {
    backgroundColor: "#ECF0F1",
    color: theme.palette.primary.main,
  },
  hoverableRow: {
    cursor: "pointer",
    "&:hover > *": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.common.white,
    },
  },
}));

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

const TablePaginationActions: React.FC<TablePaginationActionsProps> = (
  props
) => {
  const classes = useStyles();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="primera página"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="página anterior"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="página siguiente"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="última página"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

interface EnhancedTableProps<T extends object> {
  data: T[];
  columns: Column<T>[];
  initialState?: Partial<Record<string, unknown>>;
  clickableRow?: boolean;
  onClickRow?: (row: Row<T>) => void;
  users?: any[];
}

const EnhancedTable = <T extends object>({
  data = [],
  columns = [],
  initialState: controlledInitialState = {},
  clickableRow = false,
  onClickRow = () => null,
  users = [],
}: any) => {
  const styles = useStyles();
  const [expandedAccordion, setExpandedAccordion] = useState(false);
  const [expandedAccordions, setExpandedAccordions] = useState<{
    [key: number]: boolean;
  }>({});

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
  }: any = useTable<T>(
    {
      columns,
      data,
      initialState: {
        ...controlledInitialState,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const toggleAccordion = (rowIndex: number) => {
    if (users?.length > 0) {
      setExpandedAccordions((prev) => ({
        ...prev,
        [rowIndex]: !prev[rowIndex],
      }));
    }
  };

  return (
    <TableContainer>
      <Table className={styles.table} {...getTableProps()}>
        <TableHead className={styles.headers}>
          {headerGroups.map((headerGroup: any) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                let className = styles.noSortFilter;
                if (column.canFilter) className = styles.textHeader;
                else if (column.canSort) className = styles.textHeaderNotFilter;
                return (
                  <TableCell {...column.getHeaderProps()}>
                    <div className={className}>
                      <span {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        {!column.canFilter &&
                          column.canSort &&
                          (column.isSortedDesc ? (
                            <ExpandMore className={styles.headerIcon} />
                          ) : (
                            <ExpandLess className={styles.headerIcon} />
                          ))}
                      </span>
                    </div>
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {page.map((row: any, rowIndex: any) => {
            prepareRow(row);
            const isAccordionExpanded = expandedAccordions[rowIndex] || false;

            const matchingUsers: any = users?.filter((user: any) =>
              user.medicalCenters.some(
                (medicalCenter: any) => medicalCenter.id == rowIndex + 1
              )
            );
            return (
              <React.Fragment key={row.id}>
                <TableRow
                  onClick={() => {
                    toggleAccordion(rowIndex);
                    onClickRow(row);
                  }} // Pasa el índice de fila a la función toggleAccordion
                  {...row.getRowProps()}
                  className={clsx({ [styles.hoverableRow]: clickableRow })}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <TableCell
                        className={styles.rows}
                        {...cell.getCellProps()}
                        align="left"
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
                {users?.length > 0 && (
                  <TableRow>
                    <TableCell style={{ padding: 0 }} colSpan={2}>
                      <Collapse in={isAccordionExpanded}>
                        {matchingUsers?.length > 0 ? (
                          <Box>
                            <p>Usuarios asignados:</p>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Apellido</TableCell>
                                    <TableCell>Correo Electrónico</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {matchingUsers?.map((user: any) => (
                                    <TableRow key={user.id}>
                                      <TableCell>{user.firstName}</TableCell>
                                      <TableCell>{user.lastName}</TableCell>
                                      <TableCell>{user.email}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        ) : (
                          <p>No hay usuarios asignados</p>
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              component="div"
              rowsPerPageOptions={[
                5,
                10,
                25,
                { label: "Todas", value: data.length },
              ]}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { "aria-label": "Filas por página" },
                native: true,
              }}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count }: any) => {
                return `${from}-${to} de ${count}`;
              }}
              onChangePage={(event: any, newPage: any) => {
                gotoPage(newPage);
              }}
              onChangeRowsPerPage={(event: any) => {
                setPageSize(Number(event.target.value));
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default EnhancedTable;
