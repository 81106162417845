import React from "react";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { ExitToApp as AddIcon } from "@material-ui/icons";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import FormTextField from "../../components/forms/form-text-field";
import ResponsiveDialog from "../../components/ui/responsive-dialog";
import Yup from "../../utils/validations";
import useModal from "../../hooks/useModal";
import { SUSPICION_QUERY } from "../suspicions/use-suspicion";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
}));

export const CREATE_BACKGROUND_MUTATION = gql`
  mutation CreateBackgroundMutation($backgroundInput: BackgroundInput!) {
    createBackground(backgroundInput: $backgroundInput) {
      age
      diagnostic
      glasgow
      comment
    }
  }
`;

interface CreateBackgroundButtonProps {
  suspicionInfo: {
    id: string;
    rut: string;
  };
}

const createBackgroundSchema = Yup.object({
  age: Yup.number().min(1, "La edad debe ser mayor a 0").required(),
  diagnostic: Yup.string().required(),
  glasgow: Yup.number().required(),
  comment: Yup.string(),
});

const CreateBackgroundButton: React.FC<CreateBackgroundButtonProps> = ({
  suspicionInfo,
  ...buttonProps
}) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const [createBackground, { loading }] = useMutation(
    CREATE_BACKGROUND_MUTATION,
    {
      refetchQueries: [
        {
          query: SUSPICION_QUERY,
          variables: {
            suspicionId: suspicionInfo?.id,
          },
        },
      ],
    }
  );
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSubmit = async (values: any) => {
    try {
      await createBackground({
        variables: { backgroundInput: values },
      });
      enqueueSnackbar("Antecedentes agregados exitosamente", {
        variant: "success",
      });
      closeModal();
    } catch (error) {
      enqueueSnackbar(
        "Ha ocurrido un error, los antecedentes no se ingresaron exitosamente",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <>
      <Button
        onClick={openModal}
        id="create-background-btn"
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        {...buttonProps}
      >
        Ingresar
      </Button>

      <ResponsiveDialog
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <Formik
          initialValues={{
            age: "",
            diagnostic: "",
            glasgow: "",
            comment: "",
            suspicionId: suspicionInfo?.id,
          }}
          validationSchema={createBackgroundSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Container component="main" maxWidth="xs">
              <div className={classes.paper}>
                <DialogTitle id="responsive-dialog-title">
                  Antecedentes generales del paciente {suspicionInfo.rut}
                </DialogTitle>
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormTextField
                        label="Edad"
                        required
                        name="age"
                        fullWidth
                        type="number"
                        onFocus={(event: any) => event.stopPropagation()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormTextField
                        label="Glasgow"
                        name="glasgow"
                        type="number"
                        required
                        fullWidth
                        onFocus={(event: any) => event.stopPropagation()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        label="Diagnóstico"
                        required
                        name="diagnostic"
                        fullWidth
                        onFocus={(event: any) => event.stopPropagation()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        label="Comentarios adicionales"
                        name="comment"
                        fullWidth
                        onFocus={(event: any) => event.stopPropagation()}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    fullWidth
                    className={classes.submit}
                  >
                    Ingresar antecedentes
                  </Button>
                </Form>
              </div>
              <DialogActions>
                <Button onClick={closeModal} color="secondary">
                  Volver
                </Button>
              </DialogActions>
            </Container>
          )}
        </Formik>
      </ResponsiveDialog>
    </>
  );
};

export default CreateBackgroundButton;
