import React from 'react';
import { useField, FieldAttributes } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

interface FormTextFieldProps extends FieldAttributes<any> {
  label: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.text.secondary,
    },
  },
}));

const FormTextField: React.FC<FormTextFieldProps> = ({ label, name, ...props }) => {
  const [fieldProps, meta] = useField(name);
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      label={label}
      id={label}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      {...fieldProps}
      {...props}
    />
  );
};

export default FormTextField;
