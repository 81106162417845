import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/client';
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { SUSPICION_QUERY } from '../suspicions/use-suspicion';

export const CONFIRM_BACKGROUND_MUTATION = gql`
  mutation ConfirmBackgroundMutation($id: ID!) {
    confirmBackground(id: $id) {
      confirmation {
        id
        status
      }
    }
  }
`;

interface ConfirmBackgroundButtonProps {
  backgroundId: string;
  suspicionId: string;
}

const ConfirmBackgroundButton: React.FC<ConfirmBackgroundButtonProps> = ({
  backgroundId,
  suspicionId,
}) => {
  const { isModalOpen, open: openModal, close: closeModal } = useModal();
  const [confirmBackground, { loading }] = useMutation(CONFIRM_BACKGROUND_MUTATION, {
    refetchQueries: [
      {
        query: SUSPICION_QUERY,
        variables: {
          suspicionId,
        },
      },
    ],
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    closeModal();
    try {
      await confirmBackground({
        variables: { id: backgroundId },
      });
      enqueueSnackbar('Antecedentes confirmados exitosamente', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(
        'Ha ocurrido un error, los antecedentes no se han podido confirmar',
        {
          variant: 'error',
        }
      );
    }
  };

  return (
    <>
      <Button
        color="primary"
        onClick={openModal}
        id="confirm-background-btn"
        variant='contained'
      >
        Confirmar
      </Button>

      <ResponsiveDialog
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirmar antecedentes de sospecha
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer confirmar los antecedentes de este paciente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} disabled={loading} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default ConfirmBackgroundButton;