import React, { useMemo } from "react";
import { SelectFilter, TextFilter } from "../../components/table/filters";
import EnhancedTable from "../../components/ui/enhanced-table";
import { changeDateFormatShort } from "../../utils/date-format";
import { useHistory } from "react-router-dom";

interface SurgeriesTableProps {
  surgeries: any[];
}

const SurgeriesTable: React.FC<SurgeriesTableProps> = ({ surgeries }) => {
  const data = useMemo(() => surgeries, [surgeries]);

  const columns = useMemo(
    () => [
      {
        label: "RUT",
        accessor: "suspicion.rut",
        disableSortBy: true,
        Filter: TextFilter,
      },
      {
        label: "Centro médico",
        accessor: "medicalCenter.name",
        Filter: SelectFilter,
        disableSortBy: true,
      },
      {
        Header: "Crossclamp",
        accessor: "crossClamp",
        disableFilters: true,
        disableSortBy: false,
        Cell: ({ value }: { value: string }) =>
          value ? changeDateFormatShort(new Date(value)) : "--:---:--",
      },
      {
        label: "Estado actual",
        accessor: "status",
        disableSortBy: true,
        Filter: SelectFilter,
      },
    ],
    []
  );

  const history = useHistory();

  return (
    <EnhancedTable
      data={data}
      columns={columns}
      clickableRow
      onClickRow={(row: any) => history.push(`/surgeries/${row.original.id}`)}
    />
  );
};

export default SurgeriesTable;
