import React from "react";
import { Grid, Typography, Box, Link } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";
import { changeDateFormat } from "../../utils/date-format";
import CreateMedicalRecordButton from "./create-medical-record-button";
import ModifyMedicalRecordButton from "./modify-medical-record-button";
import ConfirmMedicalRecordButton from "./confirm-medical-record-button";
import { useCurrentUser } from "../authentication/use-current-user";
import Can from "../../utils/can";
import { rules } from "../../utils/rbac-rules";
import SuspicionAccordionSummary from "../suspicions/suspicion-expansion-panel-summary";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(1),
    },
    iconLabel: {
      fontSize: 14,
      marginTop: theme.spacing(1),
    },
    iconLabelLong: {
      fontSize: 14,
      marginTop: theme.spacing(1),
    },
    detailTitle: {
      paddingLeft: theme.spacing(2),
      fontSize: "16px",
      color: theme.palette.text.secondary,
    },
    detailData: {
      paddingLeft: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    detailDataTime: {
      paddingLeft: theme.spacing(2),
      color: "gray",
    },
    detailUser: {
      paddingLeft: theme.spacing(2),
      color: theme.palette.text.secondary,
      fontSize: "16px",
    },
    detailNotConfirmed: {
      paddingLeft: theme.spacing(24),
      fontWeight: "bold",
      color: theme.palette.text.secondary,
      fontSize: "16px",
    },
    detailDivider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    download: {
      color: theme.palette.primary.main,
    },
    disableDownload: {
      color: "gray",
    },
    heading: {
      fontSize: "16px",
    },
    itemsBox: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      width: "100%",
      flexDirection: "column", // Cambio de dirección para pantallas pequeñas
      [theme.breakpoints.up("lg")]: {
        flexDirection: "row", // Restaurar dirección para pantallas más grandes
      },
    },
    items: {
      display: "flex",
    },
  })
);

interface MedicalRecordDetailsProps {
  suspicion: any;
  isExpanded: boolean;
  onClick: () => void;
}

const MedicalRecordDetails: React.FC<MedicalRecordDetailsProps> = ({
  suspicion,
  isExpanded,
  onClick,
}) => {
  const classes = useStyles();
  const { user } = useCurrentUser();

  return (
    <>
      <div className={classes.root}>
        <Accordion expanded={isExpanded}>
          <SuspicionAccordionSummary
            label="Resumen Clínico / Laboratorio"
            onClick={onClick}
            isExpanded={isExpanded}
            suspicion={suspicion}
            stepName="medicalRecord"
            testId="details-medical-record-button"
          >
            {!suspicion.medicalRecord && (
              <Can
                role={user!.role}
                perform={rules.medicalRecord.create}
                yes={() =>
                  suspicion.medicalRecord ? null : (
                    <CreateMedicalRecordButton suspicion={suspicion} />
                  )
                }
              />
            )}
          </SuspicionAccordionSummary>
          {suspicion.medicalRecord ? (
            <AccordionDetails>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.detailDataTime}>
                    Última actualización:{" "}
                    {changeDateFormat(suspicion.medicalRecord.updatedAt)}
                  </Typography>
                  <Typography className={classes.detailData}>
                    Resumen clínico / laboratorio añadido por:{" "}
                  </Typography>
                  <Typography className={classes.detailUser}>
                    {suspicion.medicalRecord.creator.firstName}{" "}
                    {suspicion.medicalRecord.creator.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {suspicion.medicalRecord.confirmation?.status ? (
                    <>
                      <Typography className={classes.detailDataTime}>
                        {changeDateFormat(
                          suspicion.medicalRecord.confirmation.updatedAt
                        )}
                      </Typography>
                      <Typography className={classes.detailData}>
                        Confirmadas por
                      </Typography>
                      <Typography className={classes.detailUser}>
                        {suspicion.medicalRecord.confirmation.user.firstName}{" "}
                        {suspicion.medicalRecord.confirmation.user.lastName}
                      </Typography>
                    </>
                  ) : (
                    <Typography className={classes.detailNotConfirmed}>
                      Por confirmar
                    </Typography>
                  )}
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <div
                  className={classes.itemsBox}
                  style={{
                    flexWrap: "wrap",
                  }}
                >
                  {suspicion.medicalRecord.files?.map((item: any) => {
                    return (
                      <div
                        className={classes.items}
                        style={{
                          padding: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DescriptionIcon />
                        <Link
                          className={
                            suspicion.medicalRecord.files?.length === 0
                              ? classes.disableDownload
                              : classes.download
                          }
                          href={item}
                          target="_blank"
                          rel="noopener"
                        >
                          Ver/Descargar
                        </Link>
                      </div>
                    );
                  })}
                </div>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.detailTitle}>
                    Comentarios adicionales
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.detailData}>
                    {suspicion.medicalRecord.comments === ""
                      ? "Sin comentarios"
                      : suspicion.medicalRecord.comments}
                  </Typography>
                </Grid>
                <Grid container justifyContent="flex-end">
                  <Box>
                    <Can
                      role={user!.role}
                      perform={rules.medicalRecord.modify}
                      yes={() =>
                        suspicion.medicalRecord ? (
                          <ModifyMedicalRecordButton suspicion={suspicion} />
                        ) : null
                      }
                    />
                  </Box>
                  <Box>
                    <Can
                      role={user!.role}
                      perform={rules.medicalRecord.confirm}
                      yes={() =>
                        suspicion.medicalRecord &&
                        !suspicion.medicalRecord.confirmation?.status && (
                          <ConfirmMedicalRecordButton
                            medicalRecord={suspicion.medicalRecord}
                            suspicion={suspicion}
                          >
                            Confirmar
                          </ConfirmMedicalRecordButton>
                        )
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          ) : null}
        </Accordion>
      </div>
    </>
  );
};

export default MedicalRecordDetails;
