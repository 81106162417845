import { Paper, Grid } from "@material-ui/core/";
import React, { useMemo } from "react";
import { TextFilter } from "../../components/table/filters";
import EnhancedTable from "../../components/ui/enhanced-table";
import DeleteUserButton from "./delete-medical-center-button";
import ModifyUserButton from "./modify-medical-center-button";
import { useAllUsers } from "../users/use-all-users";

interface MedicalCenter {
  id: string;
  name: string;
}

interface MedicalCentersTableProps {
  medicalCenters: MedicalCenter[];
}

const MedicalCentersTable: React.FC<MedicalCentersTableProps> = ({
  medicalCenters,
}) => {
  const data = useMemo(() => medicalCenters, [medicalCenters]);
  const { users, loading } = useAllUsers();

  const columns = useMemo(
    () => [
      {
        label: "CENTRO MÉDICO",
        accessor: "name",
        Filter: TextFilter,
      },
      {
        Header: "ACCIONES",
        id: "actions",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row, value }: { row: any; value: string }) => {
          return (
            <Grid container>
              <Grid>
                <ModifyUserButton medicalCenterInfo={row.original} />
              </Grid>
              <Grid>
                <DeleteUserButton medicalCenterId={value} />
              </Grid>
            </Grid>
          );
        },
      },
    ],
    []
  );

  return (
    <Paper elevation={0}>
      <EnhancedTable data={data} columns={columns} childs users={users} />
    </Paper>
  );
};

export default MedicalCentersTable;
