/* eslint-disable no-template-curly-in-string */
import { changeDateFormat } from './date-format';
const Yup = require('yup');   

Yup.setLocale({
  mixed: {
    required: 'Este es un campo requerido',
  },
  string: {
    email: 'Correo es inválido',
  },
  date: {
    min: ({ min }: { min: Date }) => `La fecha debe ser posterior a ${changeDateFormat(min)}`,
  },
});

export default Yup;