import React from "react";
import { Grid, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import DescriptionIcon from "@material-ui/icons/Description";
import { changeDateFormat } from "../../utils/date-format";
import ConfirmFamilyActivationButton from "./confirm-family-activation-button";
import { useCurrentUser } from "../authentication/use-current-user";
import Can from "../../utils/can";
import { rules } from "../../utils/rbac-rules";
import SuspicionAccordionSummary from "../suspicions/suspicion-expansion-panel-summary";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  expanded: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  notExpanded: {
    backgroundColor: theme.palette.secondary.main,
  },
  buttonExpanded: {
    color: "white",
  },
  iconLabel: {
    fontSize: 14,
    marginLeft: theme.spacing(-7),
    marginTop: theme.spacing(1),
  },
  detailTitle: {
    paddingLeft: theme.spacing(2),
    fontSize: "16px",
    color: theme.palette.text.secondary,
  },
  detailData: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  detailDataTime: {
    paddingLeft: theme.spacing(2),
    color: "gray",
  },
  detailUser: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: "16px",
  },
  detailNotConfirmed: {
    paddingLeft: theme.spacing(24),
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    fontSize: "16px",
  },
  heading: {
    fontSize: "16px",
  },
  detailDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  download: {
    color: theme.palette.primary.main,
  },
  disableDownload: {
    color: "gray",
  },
  boxDetails: {
    display: "flex",
    alignItems: "center",
  },
}));

interface FamilyActivationDetailsProps {
  suspicion: any;
  isExpanded: boolean;
  onClick: () => void;
}

const FamilyActivationDetails: React.FC<FamilyActivationDetailsProps> = ({
  suspicion,
  isExpanded,
  onClick,
}) => {
  const classes = useStyles();
  const { user } = useCurrentUser();

  return (
    <>
      <div className={classes.root}>
        <Accordion expanded={isExpanded}>
          <SuspicionAccordionSummary
            label="Activación familiar"
            onClick={onClick}
            isExpanded={isExpanded}
            suspicion={suspicion}
            stepName="familyActivation"
            testId="details-family-activation-btn"
          >
            <Can
              role={user!.role}
              perform={rules.familyActivation.create}
              yes={() =>
                !suspicion.familyActivation &&
                ((
                  <ConfirmFamilyActivationButton
                    suspicionId={suspicion.id}
                    data-testid="add-family-activation-confirmation-button"
                  >
                    Ingresar
                  </ConfirmFamilyActivationButton>
                ) as any)
              }
            />
          </SuspicionAccordionSummary>
          {suspicion.familyActivation ? (
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.detailDataTime}>
                    {changeDateFormat(suspicion.familyActivation.createdAt)}
                  </Typography>
                  <Typography className={classes.detailData}>
                    Ingresados y confirmados por:
                  </Typography>
                  <Typography className={classes.detailUser}>
                    {suspicion.familyActivation.creator.firstName}{" "}
                    {suspicion.familyActivation.creator.lastName}
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <div className={classes.boxDetails}>
                    <Link
                      target="_blank"
                      href={suspicion.familyActivation.donationCertificate}
                    >
                      <Button
                        disabled={
                          !suspicion.familyActivation.donationCertificate
                        }
                      >
                        <DescriptionIcon
                          className={
                            suspicion.familyActivation.donationCertificate
                              ? classes.download
                              : classes.disableDownload
                          }
                        />
                      </Button>
                    </Link>
                    <Typography>Acta de donación</Typography>
                  </div>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider className={classes.detailDivider} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.detailTitle}>
                    Comentarios adicionales
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.detailData}>
                    {suspicion.familyActivation.comments === ""
                      ? "Sin comentarios"
                      : suspicion.familyActivation.comments}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          ) : null}
        </Accordion>
      </div>
    </>
  );
};

export default FamilyActivationDetails;
