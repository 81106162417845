import { gql, useQuery } from '@apollo/client';

export const ALL_SUSPICIONS_QUERY = gql`
  query AllSuspicions {
    getSuspicions {
      id
      rut
      status
      updatedAt
      medicalCenter {
        id
        name
      }
      organs {
        id
        organType {
          id
          name
          description
          ischemiaTime
        }
        crossClamp
        ischemia
        status
        originMedicalCenter {
          id
          name
        }
        destinationMedicalCenter {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export function useAllSuspicions() {
  const { data, loading, error, refetch } = useQuery(ALL_SUSPICIONS_QUERY);

  return { suspicions: data?.getSuspicions, loading, error, refetch };
}
