import React from 'react';
import { Paper, Typography, Box, Link, makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { addHours } from 'date-fns';
import clsx from 'clsx';

import AcceptOfferingButton from './accept-offering-button';
import DismissOfferingButton from './dismiss-offering-button';
import useCountdown from '../../hooks/useCountdown';
import { formatCountdown } from '../../utils/date-format';
import { offeringStatus } from '../../constants/status';
import { useCan } from '../../utils/can';
import { rules } from '../../utils/rbac-rules';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2), display: 'flex', flexDirection: 'column' },
  subtitle: { color: theme.palette.text.hint },
  timerSubtitle: {
    color: theme.palette.text.hint,
    marginTop: 0,
    lineHeight: 1,
  },
  footer: { display: 'flex', alignItems: 'center', minHeight: theme.spacing(5) },
  link: { flexGrow: 1 },
  dismissed: { color: theme.palette.error.main },
  accepted: { color: theme.palette.success.main },
}));

interface ActiveOfferingItemProps {
  offering: any; // Replace 'any' with the appropriate type for 'offering'
}

const ActiveOfferingItem: React.FC<ActiveOfferingItemProps> = ({ offering }) => {
  const classes = useStyles();
  const canManage = useCan({ perform: rules.offerings.manage });
  
  const { timeLeft } = useCountdown({
    targetDate: offering.expired ? new Date() : addHours(new Date(offering.activeDate), 1),
  });

  
  return (
    <Paper elevation={1} className={classes.root}>
      <Typography variant="h6">{offering.organ.organType.name}</Typography>
      <Typography variant="caption" className={classes.subtitle}>
        Tu paciente está en {offering.priority}° prioridad
      </Typography>
      <Typography variant="caption" className={classes.subtitle}>
        Rut: {offering.organ.suspicion.rut}
      </Typography>
      {canManage && (
        <Typography variant="overline">Para {offering.medicalCenter.name}</Typography>
      )}
      <Typography variant="h2">{formatCountdown(timeLeft)}</Typography>
      <Typography variant="overline" className={classes.timerSubtitle}>
        Tiempo restante
      </Typography>
      <Box className={classes.footer}>
        <Link
          className={classes.link}
          component={RouterLink}
          to={`/suspicions/${offering.organ.suspicion.id}`}
        >
          Detalles de donante
        </Link>
        {offering.status === offeringStatus.pending ? (
          <>
          {/* disabled={timeLeft < 1000} */}
            <DismissOfferingButton offering={offering} timeLeft={timeLeft} />
            <AcceptOfferingButton offering={offering}  />
          </>
        ) : (
          <Typography
            variant="overline"
            className={clsx({
              [classes.dismissed]: offering.status === offeringStatus.dismissed,
              [classes.accepted]: offering.status === offeringStatus.accepted,
            })}
          >
            {offering.status}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default ActiveOfferingItem;
