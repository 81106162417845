import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

const s3Client = new S3Client({
  region: "us-east-1",
  credentials: {
    accessKeyId: "AKIAYRK5V64H3UOVR3U3",
    secretAccessKey: "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",
  },
});
export const uploadFile = async (file: File, credentials: any) => {
  // Generate a unique filename or use the existing one
  const fileName = `uploads/${Date.now()}_${file.name}`;

  // Upload the file to S3
  try {
    await s3Client.send(
      new PutObjectCommand({
        ...credentials,
        Bucket: "transplan-files-dev",
        Key: fileName,
        Body: file,
        ACL: "public-read", // Make the file public
        ContentType: file.type,
      })
    );

    // Get the public URL of the uploaded file
    return `https://transplan-files-dev.s3.us-east-1.amazonaws.com/${fileName}`;
  } catch (error) {
    return error;
  }
};
