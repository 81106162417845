import React from "react";
import clsx from "clsx";
import {
  makeStyles,
  AccordionSummary,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

import StatusIcon from "../../components/ui/status-icon";

const useStyles = makeStyles((theme) => ({
  root: { minHeight: 54 },
  confirmed: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  expanded: { backgroundColor: theme.palette.primary.main, color: "white" },
  notExpanded: { backgroundColor: theme.palette.secondary.main },
  expansionPanelContent: { alignItems: "center", flexDirection: "row" },
  chevron: { color: theme.palette.common.white },
  chevronDark: { color: theme.palette.primary.main },
  heading: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 16,
    fontWeight: 500,
  },
}));

interface SuspicionAccordionSummaryProps {
  label: string;
  isExpanded: boolean;
  onClick: () => void;
  children: React.ReactNode;
  suspicion: {
    [key: string]: any;
  };
  stepName: string;
  testId: string;
}

const SuspicionAccordionSummary: React.FC<SuspicionAccordionSummaryProps> = ({
  label,
  isExpanded,
  onClick,
  children,
  suspicion,
  stepName,
  testId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isCreated = suspicion?.[stepName];

  let isConfirmed = false;
  if (isCreated && ["brainDeath", "familyActivation"].includes(stepName))
    isConfirmed = true;
  else if (isCreated) isConfirmed = suspicion[stepName]?.confirmation?.status;

  return (
    <AccordionSummary
      className={clsx(classes.root, {
        [classes.expanded]: isExpanded && !isConfirmed,
        [classes.notExpanded]: !isExpanded && !isConfirmed,
        [classes.confirmed]: isConfirmed,
      })}
      classes={{ content: classes.expansionPanelContent }}
      onClick={() => isCreated && onClick()}
      expandIcon={
        isCreated && (
          <ExpandMoreIcon
            data-testid={testId}
            className={clsx({
              [classes.chevron]: isExpanded || isConfirmed,
              [classes.chevronDark]: !isExpanded && !isConfirmed,
            })}
          />
        )
      }
    >
      {isCreated && !isConfirmed && (
        <StatusIcon color={theme.palette.success.main} />
      )}
      <Typography className={classes.heading}>{label}</Typography>
      {!isCreated && children}
    </AccordionSummary>
  );
};

export default SuspicionAccordionSummary;
