import React, { useEffect } from "react";
import { Paper, Typography, Box, Button } from "@material-ui/core";
import { Refresh as RefreshIcon } from "@material-ui/icons";
import FullPageSpinner from "../components/ui/full-page-spinner";
import SuspicionsTable from "../features/suspicions/suspicions-table";
import { useAllSuspicions } from "../features/suspicions/use-all-suspicions";
import { useCan } from "../utils/can";
import { rules } from "../utils/rbac-rules";

const SuspicionsPage: React.FC = () => {
  const canGet = useCan({ perform: rules.suspicions.get });
  const { suspicions, loading, refetch } = useAllSuspicions();

  useEffect(() => {
    refetch();
  }, []);

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      {canGet && (
        <Box>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h6" component="div">
              Sospechas
            </Typography>
            <Button
              onClick={() => refetch()}
              startIcon={<RefreshIcon />}
              variant="contained"
              color="primary"
            >
              Actualizar
            </Button>
          </Box>
          <Paper elevation={0}>
            <SuspicionsTable suspicions={suspicions} />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default SuspicionsPage;
