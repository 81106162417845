import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/client';
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import Yup from '../../utils/validations';
import { useSnackbar } from 'notistack';

import ResponsiveDialog from '../../components/ui/responsive-dialog';
import FormDateTimePicker from '../../components/forms/form-datetime-picker';
import { ALL_SURGERIES_QUERY } from './use-all-surgeries';
import { SURGERY_QUERY } from './use-surgery';

const useStyles = makeStyles((theme) => ({
  content: { margin: theme.spacing(1, 0) },
}));

interface ScheduleCrossClampDialogProps {
  surgery: any;
  open: boolean;
  onClose: () => void;
  onCrossClamp: any;
}

const SCHEDULE_CROSS_CLAMP_MUTATION = gql`
  mutation crossClamp($surgeryId: ID!, $crossClampDate: Date!) {
    crossClamp(surgeryId: $surgeryId, crossClampDate: $crossClampDate) {
      id
    }
  }
`;

const ScheduleCrossClampDialog: React.FC<ScheduleCrossClampDialogProps> = ({
  surgery,
  open,
  onClose,
  onCrossClamp,
}) => {
  const [crossClamp, { loading }] = useMutation(SCHEDULE_CROSS_CLAMP_MUTATION, {
    refetchQueries: [
      { query: ALL_SURGERIES_QUERY },
      { query: SURGERY_QUERY, variables: { surgeryId: surgery.id } },
    ],
  });

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values: { crossClampDate: Date }, { resetForm }: any) => {
    try {
      await crossClamp({ variables: { ...values, surgeryId: surgery.id } });
      await onCrossClamp ()
      enqueueSnackbar('Cross Clamp agendado correctamente', { variant: 'success' });
      onClose();
      resetForm({});
    } catch (err) {
      enqueueSnackbar('Ha ocurrido un error agendando el Cross Clamp', {
        variant: 'error',
      });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ crossClampDate: new Date() }}
      validationSchema={Yup.object({
        crossClampDate: Yup.date().max(new Date()).required(),
      })}
    >
      {({ submitForm }) => (
        <Form>
          <ResponsiveDialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
            onClick={(event : any) => event.stopPropagation()}
          >
            <DialogTitle>
              Ingresar cross-clamp del paciente {surgery.suspicion.rut}
            </DialogTitle>
            <DialogContent className={classes.content}>
              <FormDateTimePicker
                label="Fecha y hora"
                name="crossClampDate"
                // disableFuture
                // fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => onClose()}>Volver</Button>
              <Button
                onClick={() => submitForm()}
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Ingresar
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        </Form>
      )}
    </Formik>
  );
};

export default ScheduleCrossClampDialog;
