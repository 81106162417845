import { gql, QueryResult, useQuery } from '@apollo/client';

interface ConfirmedSuspicion {
  id: string;
  rut: string;
  status: string;
  updatedAt: string;
  medicalCenter: {
    id: string;
    name: string;
  };
  surgery: {
    id: string;
  };
  organs: {
    id: string;
    organType: {
      id: string;
      name: string;
      description: string;
      ischemiaTime: number;
    };
    crossClamp: string;
    startProcure: string;
    endProcure: string;
    ischemia: string;
    status: string;
    originMedicalCenter: {
      id: string;
      name: string;
    };
    destinationMedicalCenter: {
      id: string;
      name: string;
    };
    offerings: {
      id: string;
      status: string;
      priority: number;
      createdAt: string;
      medicalCenter: {
        name: string;
      };
    }[];
    createdAt: string;
    updatedAt: string;
  }[];
}

interface ConfirmedSuspicionsData {
  getConfirmedSuspicions: ConfirmedSuspicion[];
}

export const CONFIRMED_SUSPICIONS_QUERY = gql`
  query ConfirmedSuspicions {
    getConfirmedSuspicions {
      id
      rut
      status
      updatedAt
      medicalCenter {
        id
        name
      }
      surgery {
        id
      }
      organs {
        id
        organType {
          id
          name
          description
          ischemiaTime
        }
        crossClamp
        ischemia
        status
        startProcure
        endProcure
        originMedicalCenter {
          id
          name
        }
        destinationMedicalCenter {
          id
          name
        }
        offerings {
          id
          status
          priority
          createdAt
          medicalCenter {
            name
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export function useConfirmedSuspicions(): QueryResult<ConfirmedSuspicionsData> {
  return useQuery<ConfirmedSuspicionsData>(CONFIRMED_SUSPICIONS_QUERY);
}
