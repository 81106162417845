import React from 'react';
import { useField } from 'formik';
import { DateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';

interface FormDateTimePickerProps {
  label: string;
  name: string;
  format?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.text.secondary,
    },
  },
}));

const FormDateTimePicker: React.FC<FormDateTimePickerProps> = ({ label, name, format = 'dd/MM/yyyy HH:mm', ...props }) => {
  const [fieldProps, meta, helpers] = useField(name);
  const classes = useStyles();

  return (
    <DateTimePicker
      label={label}
      value={fieldProps.value}
      onChange={(date) => helpers.setValue(date)}
      onBlur={() => !meta.touched && helpers.setTouched(true)}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      className={classes.root}
      format={format}
      {...props}
    />
  );
};

export default FormDateTimePicker;
