import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField } from "formik";
import React from "react";

interface FormSingleAutocompleteProps<T> {
  label: string;
  name: string;
  options: T[];
  getOptionLabel: (option: T) => string;
  renderOption?: (option: T) => React.ReactNode;
  getValueFromOption: (option: T) => any;
  [key: string]: any;
}

const FormSingleAutocomplete = <T extends {}>({
  label,
  name,
  options = [],
  getOptionLabel,
  renderOption,
  getValueFromOption,
  ...props
}: FormSingleAutocompleteProps<T>) => {
  const [, meta, helpers] = useField(name);

  const handleChange = (event: React.ChangeEvent<{}>, newOption: T | null) => {
    if (!newOption) {
      helpers.setValue(meta.initialValue);
    } else {
      helpers.setValue(getValueFromOption(newOption));
      
    }
  };

  return (
    <Autocomplete
      options={options}
      
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          label={label}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
          {...params}
        />
      )}
      autoHighlight
      onChange={handleChange as any }
      {...props}
    />
  );
};

export default FormSingleAutocomplete;
