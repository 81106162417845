import React from 'react';
import { Grid } from '@material-ui/core';

import { useCurrentUser } from '../authentication/use-current-user';
import OrganItem from './organ-item';

interface OrgansPanelProps {
  surgery:  {
    organs: Array<any>;
    assignedOrgans: Array<any>;
  };
}

const OrgansPanel: React.FC<OrgansPanelProps> = ({ surgery }) => {
  const { user }: any = useCurrentUser();

  let organs: Array<any> = [];
  switch (user.role) {
    case 'admin':
    case 'coordinator':
      organs = surgery.organs;
      break;
    case 'procurer':
      organs = surgery.assignedOrgans;
      break;
    default:
      throw new Error('This role is not defined');
  }

  return (
    <Grid container spacing={3}>
      {organs.map((organ: any) => (
        <Grid key={organ.id} item sm={12} md={6}>
          <OrganItem surgery={surgery as any} organ={organ as any} />
        </Grid>
      ))}
    </Grid>
  );
};

export default OrgansPanel;
