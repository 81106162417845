import { useMutation } from '@apollo/client';
import { gql } from 'apollo-boost';

import { SUSPICION_QUERY } from '../suspicions/use-suspicion';

export const CREATE_INCLUSION_CRITERIA_MUTATION = gql`
  mutation CreateInclusionCriteria($inclusionCriteriaInput: InclusionCriteriaInput!) {
    createInclusionCriteria(inclusionCriteriaInput: $inclusionCriteriaInput) {
      id
      createdAt
      vih
      vhb
      vhc
      hasSepsis
      hasCancer
      confirmation {
        id
        status
        updatedAt
        user {
          firstName
          lastName
        }
      }
      creator {
        firstName
        lastName
      }
      comments
    }
  }
`;

interface CreateInclusionCriteriaResponse {
  id: string;
  createdAt: Date;
  vih: string;
  vhb: string;
  vhc: string;
  hasSepsis: boolean;
  hasCancer: boolean;
  confirmation: {
    id: string;
    status: boolean;
    updatedAt: Date;
    user: {
      firstName: string;
      lastName: string;
    };
  };
  creator: {
    firstName: string;
    lastName: string;
  };
  comments: string;
}

interface CreateInclusionCriteriaInput {
  values: {
    vih: string;
    vhb: string;
    vhc: string;
    hasSepsis: boolean;
    hasCancer: boolean;
    comments: string;
  };
  user: {
    firstName: string;
    lastName: string;
  };
}

export const createInclusionCriteriaResponse = ({
  values,
  user,
}: CreateInclusionCriteriaInput): any => ({
  __typename: 'Mutation',
  createInclusionCriteria: {
    id: 'some-id',
    __typename: 'InclusionCriteria',
    createdAt: new Date(),
    vih: values.vih,
    vhb: values.vhb,
    vhc: values.vhc,
    hasSepsis: values.hasSepsis,
    hasCancer: values.hasCancer,
    confirmation: {
      __typename: 'Confirmation',
      id: 'some-confirmation-id',
      status: false,
      updatedAt: new Date(),
      user: {
        __typename: 'User',
        firstName: user.firstName,
        lastName: user.lastName,
      },
    },
    creator: {
      __typename: 'User',
      firstName: user.firstName,
      lastName: user.lastName,
    },
    comments: values.comments,
  },
});

export function useCreateInclusionCriteria({ suspicionId }: { suspicionId: string }) {
  const [createInclusionCriteriaMutation] = useMutation<
    { createInclusionCriteria: CreateInclusionCriteriaResponse },
    { inclusionCriteriaInput: CreateInclusionCriteriaInput }
  >(CREATE_INCLUSION_CRITERIA_MUTATION, {
    update: (cache, { data }) => {
      const createdInclusionCriteria = data?.createInclusionCriteria;
      if (createdInclusionCriteria) {
        const { getSuspicion: currentSuspicion }: any = cache.readQuery({
          query: SUSPICION_QUERY,
          variables: { suspicionId },
        });
        const updatedSuspicion = {
          ...currentSuspicion,
          status: 'Activo',
          inclusionCriteria: createdInclusionCriteria,
        };
        cache.writeQuery({
          query: SUSPICION_QUERY,
          variables: { suspicionId },
          data: {
            getSuspicion: updatedSuspicion,
          },
        });
      }
    },
  });
  return createInclusionCriteriaMutation;
}
