import { gql, useQuery } from '@apollo/client';

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

const USERS_LIST_QUERY = gql`
  query {
    getUsers {
      id
      firstName
      lastName
      email
      role
      medicalCenters {
        id
      }
    }
  }
`;

function useAllUsers() {
  const { data, loading, error } = useQuery<{ getUsers: User[] }>(USERS_LIST_QUERY);

  const users = data?.getUsers;
  return { users, loading, error };
}

export { USERS_LIST_QUERY, useAllUsers };
