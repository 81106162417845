import React, { useEffect } from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { gql } from 'apollo-boost';
import { useSnackbar } from 'notistack';

import { OFFERINGS_QUERY } from './use-all-offerings';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { CONFIRMED_SUSPICIONS_QUERY } from '../organs/use-confirmed-suspicions';

const DISMISS_OFFERING_MUTATION = gql`
  mutation DismissOffering($offeringId: ID!) {
    rejectOffering(id: $offeringId) {
      id
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
  },
}));

interface DismissOfferingButtonProps {
  offering: any; // Replace 'any' with the appropriate type for 'offering'
  timeLeft: any; // Replace 'any' with the appropriate type for 'offering'
}

const DismissOfferingButton: React.FC<DismissOfferingButtonProps> = ({ offering, timeLeft, ...buttonProps }) => {
  const classes = useStyles();
  const { isModalOpen, open, close } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [dismissOffering, { loading }] = useMutation(DISMISS_OFFERING_MUTATION, {
    refetchQueries: [
      { query: OFFERINGS_QUERY },
      { query: OFFERINGS_QUERY, variables: { expired: true } },
      { query: CONFIRMED_SUSPICIONS_QUERY },
    ],
  });

  useEffect(() => {
    if (timeLeft == 0) {
      handleSubmit(true)
    }
  }, [timeLeft]);

  const handleSubmit = async (silence = false) => {
    try {
      await dismissOffering({ variables: { offeringId: offering.id } });
      if (silence == false){
      enqueueSnackbar('Oferta rechazada', { variant: 'success' });
      }
      close();
    } catch (err) {
    if (silence == false){
      enqueueSnackbar('Hubo un error al rechazada la oferta', { variant: 'error' });
      }
    }
  };

  return (
    <>
      <Button
        className={classes.button}
        onClick={open}
        variant="text"
        color="primary"
        data-testid="dismiss-offering-button"
        {...buttonProps}
      >
        Rechazar
      </Button>
      <ResponsiveDialog open={isModalOpen} onClose={close}>
        <DialogTitle id="confirm-suspicion-dialog">Rechazar oferta</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer rechazar el{' '}
            {offering.organ.organType.name.toLowerCase()} de{' '}
            {offering.organ.suspicion.rut}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="secondary">
            Volver
          </Button>
          <Button onClick={() => handleSubmit(false)} disabled={loading} color="primary" type="submit">
            Rechazar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default DismissOfferingButton;
