import React from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { gql } from 'apollo-boost';
import { useSnackbar } from 'notistack';

import { OFFERINGS_QUERY } from './use-all-offerings';
import ResponsiveDialog from '../../components/ui/responsive-dialog';
import useModal from '../../hooks/useModal';
import { CONFIRMED_SUSPICIONS_QUERY } from '../organs/use-confirmed-suspicions';

interface AcceptOfferingButtonProps {
  offering: any; // Replace 'any' with the appropriate type for 'offering'
}

const ACCEPT_OFFERING_MUTATION = gql`
  mutation AcceptOffering($offeringId: ID!) {
    acceptOffering(id: $offeringId) {
      id
    }
  }
`;

const AcceptOfferingButton: React.FC<AcceptOfferingButtonProps> = ({ offering, ...buttonProps }) => {
  const { isModalOpen, open, close } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [acceptOffering, { loading }] = useMutation(ACCEPT_OFFERING_MUTATION, {
    refetchQueries: [
      { query: OFFERINGS_QUERY },
      { query: OFFERINGS_QUERY, variables: { expired: true } },
      { query: CONFIRMED_SUSPICIONS_QUERY },
    ],
  });

  const handleSubmit = async () => {
    try {
      await acceptOffering({ variables: { offeringId: offering.id } });
      enqueueSnackbar('Oferta aceptada', { variant: 'success' });
      close();
    } catch (err) {
      enqueueSnackbar('Hubo un error al aceptar la oferta', { variant: 'error' });
    }
  };

  return (
    <>
      <Button
        onClick={open}
        variant="contained"
        color="primary"
        data-testid="accept-offering-button"
        {...buttonProps}
      >
        Aceptar
      </Button>
      <ResponsiveDialog  open={isModalOpen} onClose={close}>
        <DialogTitle id="confirm-suspicion-dialog">Aceptar oferta</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de querer aceptar el{' '}
            {offering.organ.organType.name.toLowerCase()} de{' '}
            {offering.organ.suspicion.rut}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="secondary">
            Volver
          </Button>
          <Button onClick={handleSubmit} disabled={loading} color="primary" type="submit">
            Confirmar
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default AcceptOfferingButton;
