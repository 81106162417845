const rules = {
  suspicions: {
    create: 'suspicions:create',
    get: 'suspicions:get',
    delete: 'suspicions:delete',
    confirm: 'suspicions:confirm',
  },
  users: {
    get: 'users:get',
  },
  hospitals: {
    get: 'hospitals:get',
  },
  background: {
    create: 'background:create',
    confirm: 'background:confirm',
  },
  patients: {
    create: 'patients:create',
    get: 'patients:get',
  },
  organs: {
    create: 'organs:create',
    get: 'organs:get',
    allocate: 'organs:allocate',
  },
  offerings: {
    manage: 'offerings:manage',
  },
  inclusionCriteria: {
    create: 'inclusionCriteria:create',
    confirm: 'inclusionCriteria:confirm',
  },
  brainDeath: {
    create: 'brainDeath:create',
  },
  familyActivation: {
    create: 'familyActivation:create',
  },
  medicalRecord: {
    create: 'medicalRecord:create',
    modify: 'medicalRecord:modify',
    confirm: 'medicalRecord:confirm',
  },
  laboratory: {
    create: 'laboratory:create',
  },
  surgery: {
    get: 'surgery:get',
    schedule: 'surgery:schedule',
    crossClamp: 'surgery:crossClamp',
    confirm: 'surgery:confirm',
    reject: 'surgery:organ:reject',
  },
} as const;

const rulesUserRoles: any = {
  admin: [
    rules.suspicions.create,
    rules.suspicions.get,
    rules.suspicions.delete,
    rules.suspicions.confirm,
    rules.users.get,
    rules.hospitals.get,
    rules.background.create,
    rules.background.confirm,
    rules.organs.get,
    rules.organs.create,
    rules.organs.allocate,
    rules.offerings.manage,
    rules.patients.get,
    rules.patients.create,
    rules.inclusionCriteria.create,
    rules.inclusionCriteria.confirm,
    rules.brainDeath.create,
    rules.familyActivation.create,
    rules.medicalRecord.create,
    rules.medicalRecord.modify,
    rules.medicalRecord.confirm,
    rules.laboratory.create,
    rules.surgery.get,
    rules.surgery.schedule,
    rules.surgery.crossClamp,
    rules.surgery.confirm,
    rules.surgery.reject,
  ],
  user: [rules.suspicions.create],
  uciMedic: [
    rules.suspicions.create,
    rules.suspicions.get,
    rules.background.create,
    rules.inclusionCriteria.create,
    rules.medicalRecord.create,
    rules.medicalRecord.modify,
  ],
  procurer: [
    rules.suspicions.create,
    rules.suspicions.get,
    rules.suspicions.delete,
    rules.patients.get,
    rules.patients.create,
    rules.background.create,
    rules.background.confirm,
    rules.inclusionCriteria.confirm,
    rules.inclusionCriteria.create,
    rules.brainDeath.create,
    rules.familyActivation.create,
    rules.medicalRecord.create,
    rules.medicalRecord.modify,
    rules.medicalRecord.confirm,
    rules.laboratory.create,
    rules.surgery.get,
    rules.surgery.crossClamp,
    rules.surgery.confirm,
    rules.surgery.reject,
  ],
  coordinator: [
    rules.organs.get,
    rules.organs.create,
    rules.organs.allocate,
    rules.patients.get,
    rules.patients.create,
    rules.suspicions.get,
    rules.suspicions.confirm,
    rules.surgery.get,
    rules.surgery.schedule,
  ],
} as const;

export { rules, rulesUserRoles };